import React from "react";
import PageHeader from "../../app/layout/ui/header/PageHeader";
import SubHeader from "../../app/layout/ui/header/SubHeader";
import ClientsTable from "./ClientsTable";

const Clients = () => {
  return (
    <div>
      <PageHeader title="Clients" />
      <SubHeader
        title="List of all ReKy Clients"
        actionTo="clients/create"
        actionTxt="Create Client"
        actionType="create"
      />
      <div className="page-wrapper">
        <ClientsTable />
      </div>
    </div>
  );
};
export default Clients;
