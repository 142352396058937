import { createReducer } from '../../app/common/util/reducerUtil';
import { FETCH_ALL_BOXES } from './boxesConstants';

const initialState = {};

export const fetchAllBoxesData = (state, payload = {}) => {
	return payload;
};

export default createReducer(initialState, {
	[FETCH_ALL_BOXES]: fetchAllBoxesData
});
