import React from "react";
import StatsCard from "./StatsCard";
import styled from "styled-components";
import StockStatsDrawerLoader from "./StockStatsDrawerLoader";
import { useAuth } from "../../../app/context/auth";
import { useStats } from "../../../app/context/stats";

const StartsRow = styled.div`
  background: #fff;
  padding: 30px;
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  grid-gap: 30px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const StockStatsDrawer = () => {
  const { role } = useAuth();
  const stats = useStats();

  if ((role.isSuperAdmin && !stats.clients) || !stats.boxes || !stats.passes)
    return <StockStatsDrawerLoader />;

  return (
    <StartsRow columns={role.isSuperAdmin ? 3 : 2}>
      {role.isSuperAdmin ? (
        <StatsCard title="Clients" value={stats.clients.all} />
      ) : null}
      <StatsCard title="Boxes" value={stats.boxes.all} />
      <StatsCard title="Passes" value={stats.passes.all} />
    </StartsRow>
  );
};

export default StockStatsDrawer;
