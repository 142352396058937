import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm, Field } from "redux-form";
import { useHistory, withRouter } from "react-router-dom";
import { Segment, Form, Button, Header, Divider } from "semantic-ui-react";
import {
  composeValidators,
  combineValidators,
  isRequired,
  hasLengthGreaterThan,
  hasLengthLessThan,
  createValidator,
} from "revalidate";
import TextInput from "../../../app/common/form/TextInput";
import DropdownInput from "../../../app/common/form/DropDownInput";
import ClientSelectInput from "../../../app/common/form/ClientSelectInput";
import { useUsers } from "../../../app/context/users";
import { useAsync } from "../../../app/context/async";

const mapState = (state, ownProps) => {
  let user = ownProps.user ? ownProps.user : {};

  return {
    user,
    initialValues: user,
  };
};

const userRoleOptions = [
  { key: 1, text: "Super User", value: 1 },
  { key: 2, text: "Client Admin", value: 2 },
  { key: 3, text: "Client User", value: 3 },
];

const statusOptions = [
  { key: true, text: "Active Web User", value: true },
  { key: false, text: "Deactivated Web User", value: false },
];

const isValidEmail = createValidator(
  (message) => (value) => {
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(value)) {
      return message;
    }
  },
  "Invalid email address"
);

const validate = combineValidators({
  firstName: composeValidators(
    isRequired({ message: "Client Name is required" }),
    hasLengthGreaterThan(2)({
      message: "Name must be longer than 2 characters.",
    }),
    hasLengthLessThan(46)({
      message: "Name must be shorter than 45 characters.",
    })
  )(),
  tblClientProfileID: isRequired({ message: "Linked Client is required" }),
  email: composeValidators(
    isRequired({ message: "Email is required" }),

    isValidEmail
  )(),
  pass: composeValidators(
    hasLengthGreaterThan(3)({
      message: "Password must be longer than 3 characters.",
    })
  ),
});

const WebUsersForm = ({ invalid, submitting, isNew, handleSubmit }) => {
  const { loading } = useAsync();
  const [pass, setPass] = useState(null);
  const { updateUser, createUser } = useUsers();
  const history = useHistory();
  const onPassType = (evt) => {
    setPass(evt.target.value);
  };
  const onFormSubmit = (values) => {
    if (!isNew) {
      updateUser(values, pass);
    } else {
      createUser(values, pass);
      history.push("/users");
    }
  };

  return (
    <Segment loading={loading}>
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Form.Group widths="equal">
          <Field
            name="firstName"
            type="text"
            label="First Name"
            component={TextInput}
            placeholder="Enter First Name"
          />
          <Field
            name="lastName"
            type="text"
            label="Last Name"
            component={TextInput}
            placeholder="Enter Last Name"
          />
        </Form.Group>

        <Header sub color="blue" content="Contact Details" />
        <Divider />
        <Form.Group widths="equal">
          <Field
            name="email"
            type="email"
            label="Email"
            component={TextInput}
            placeholder="name@domain.com"
          />
          <Field
            name="mobile"
            type="text"
            label="Mobile"
            component={TextInput}
            placeholder="(000) 000-0000"
          />
        </Form.Group>

        <Header sub color="blue" content="Authentication Details" />
        <Divider />
        <Form.Group widths="equal">
          <Form.Field>
            <label>{isNew ? "Create Password" : "Update Password"}</label>
            <input
              name="pass"
              type="password"
              placeholder="••••••"
              onKeyUp={onPassType}
            />
          </Form.Field>
          <Field
            name="pwdExpires"
            type="number"
            label="Password Expired (In Days)"
            component={TextInput}
          />
          <Field
            name="tblACLID"
            type="text"
            label="User Role"
            component={DropdownInput}
            options={userRoleOptions}
            defaultValue={3}
          />
          <Field
            name="status"
            type="text"
            label="Status"
            component={DropdownInput}
            options={statusOptions}
            defaultValue={true}
          />
        </Form.Group>

        <Header sub color="blue" content="Company Details" />
        <Divider />
        <Form.Group widths="equal">
          <Field
            name="tblClientProfileID"
            label="Client"
            component={ClientSelectInput}
          />
        </Form.Group>

        <Divider />
        <Button
          disabled={invalid || submitting}
          color="blue"
          type="submit"
          loading={loading}
        >
          {isNew ? "CREATE USER" : "UPDATE USER"}
        </Button>
        <Button.Group floated="right">
          <Button onClick={history.goBack} type="button">
            GO BACK
          </Button>
        </Button.Group>
      </Form>
    </Segment>
  );
};

export default compose(
  withRouter,
  connect(mapState),
  reduxForm({ form: "webUsersForm", enableReinitialize: true, validate })
)(WebUsersForm);
