import React, { Component } from "react";
import { Form, Label } from "semantic-ui-react";
import MaskedInput from "react-maskedinput";

class MaskedTextInput extends Component {
  state = {
    btMac: ""
  };
  _onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const {
      mask,
      name,
      size,
      input,
      width,
      placeholder,
      label,
      disabled,
      meta: { touched, error }
    } = this.props;
    return (
      <Form.Field error={touched && !!error} width={width}>
        {label && <label>{label}</label>}
        <MaskedInput
          {...input}
          mask={mask}
          name={name}
          size={size}
          onChange={this._onChange}
          placeholder={placeholder}
          disabled={disabled}
        />

        {touched && error && (
          <Label basic color="red" pointing>
            {error}
          </Label>
        )}
      </Form.Field>
    );
  }
}

export default MaskedTextInput;
