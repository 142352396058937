import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useBoxes } from "../../../app/context/boxes";
import PageHeader from "../../../app/layout/ui/header/PageHeader";
import SubHeader from "../../../app/layout/ui/header/SubHeader";
import PassForm from "../form";

const CreatePass = () => {
  const { boxes } = useBoxes();
  const params = useParams();
  const box = useMemo(() => {
    console.log(params);
    if (boxes && params.boxId)
      return boxes?.find((p) => p.id === parseInt(params.boxId));
  }, [params, boxes]);

  return (
    <div>
      <PageHeader title="New Pass" />
      <SubHeader title="Create a new ReKy Pass" />

      <div className="page-wrapper">
        <PassForm isNew box={box} />
      </div>
    </div>
  );
};
export default CreatePass;
