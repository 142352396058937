import { createReducer } from '../../app/common/util/reducerUtil';
import { FETCH_ALL_PASSES, FETCH_ALL_BOXES_PASSES } from './passesConstants';

const initialState = {};

export const fetchAllPassesData = (state, payload = {}) => {
	return payload;
};

export const fetchAllBoxesPassesData = (state, payload = {}) => {
	return payload;
};

export default createReducer(initialState, {
	[FETCH_ALL_PASSES]: fetchAllPassesData,
	[FETCH_ALL_BOXES_PASSES]: fetchAllBoxesPassesData,
});
