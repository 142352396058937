import React, { useEffect, useState } from "react";
import { Grid, Card, Icon, Label, Segment } from "semantic-ui-react";
import PageHeader from "../../../app/layout/ui/header/PageHeader";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import PassesTableList from "../../passes/passesListTable";
import SubHeader from "../../../app/layout/ui/header/SubHeader";
import { useBoxes } from "../../../app/context/boxes";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { usePasses } from "../../../app/context/passes";
import { useApi } from "../../../app/context/api";

const BoxDetails = () => {
  const params = useParams();
  const { boxes } = useBoxes();
  const box = useMemo(() => {
    if (boxes) return boxes?.find((box) => box.id === parseInt(params.boxId));
  }, [boxes, params]);

  const { getBoxesPasses, boxPasses, clearBoxPasses } = usePasses();

  const api = useApi();
  useEffect(() => {
    clearBoxPasses();
  }, [clearBoxPasses]);

  useEffect(() => {
    api.authorized && box ? getBoxesPasses(box) : console.log("not authorized");
  }, [api, box, getBoxesPasses]);

  return box && boxPasses ? (
    <div>
      <PageHeader title="Box Details" />
      <SubHeader
        title={`${box.friendlyName} details`}
        actionTo={`/boxes/box/${box.id}`}
        actionTxt="Edit Box"
        actionType="edit"
        withCreatePass
        boxID={box.id}
      />

      <div className="page-wrapper">
        <Segment loading={!box} basic>
          <Grid>
            <Grid.Column>
              <div className="detail-page-count-header one-col">
                <div className="col">
                  <div className="title">
                    <Icon name="address card" /> Access Passes
                  </div>
                  <div className="value">{box.accessPassCount}</div>
                </div>
              </div>

              <h2 className="in-page-heading">Passes</h2>
              {boxPasses.length > 0 ? (
                <PassesTableList passes={boxPasses} />
              ) : (
                <div className="no-results">
                  No passes found linked to {box.friendlyName}.
                </div>
              )}
            </Grid.Column>
          </Grid>
          <h2 className="in-page-heading">Box Details</h2>
          <Grid columns={3}>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header>
                    {box.friendlyName}
                    <Label horizontal className="pull-right">
                      Box
                    </Label>
                  </Card.Header>
                </Card.Content>
                <Card.Content extra>
                  {box.boxtype}
                  <Label horizontal className="pull-right">
                    Box Type
                  </Label>
                </Card.Content>
                {box.btMac && (
                  <Card.Content extra>
                    {box.btMac}
                    <Label horizontal className="pull-right">
                      BT Mac
                    </Label>
                  </Card.Content>
                )}
                {box.commissionDate && (
                  <Card.Content extra>
                    {box.commissionDate}
                    <Label horizontal className="pull-right">
                      Commission Date
                    </Label>
                  </Card.Content>
                )}
                {box.firmwareSDK && (
                  <Card.Content extra>
                    {box.firmwareSDK}
                    <Label horizontal className="pull-right">
                      Firmware SDK
                    </Label>
                  </Card.Content>
                )}
                {box.firmwareVersion && (
                  <Card.Content extra>
                    {box.firmwareVersion}
                    <Label horizontal className="pull-right">
                      Firmware Ver
                    </Label>
                  </Card.Content>
                )}
                {box.hardwareVersion && (
                  <Card.Content extra>
                    {box.hardwareVersion}
                    <Label horizontal className="pull-right">
                      Hardware Ver
                    </Label>
                  </Card.Content>
                )}
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header>Address</Card.Header>
                </Card.Content>
                <Card.Content extra>
                  <p>
                    {box.locName ? box.locName : <span className="blank" />}
                  </p>
                  <p>
                    {box.address1 ? (
                      box.address1
                    ) : (
                      <span className="blank w90" />
                    )}
                  </p>
                  <p>
                    {box.address2 ? (
                      box.address2
                    ) : (
                      <span className="blank w70" />
                    )}
                  </p>
                  <p>
                    {box.address3 ? box.address3 : <span className="blank" />}
                  </p>
                  <p>
                    {box.address4 ? (
                      box.address4
                    ) : (
                      <span className="blank w40" />
                    )}
                  </p>
                  <p>
                    {box.postalCode ? (
                      box.postalCode
                    ) : (
                      <span className="blank w60" />
                    )}
                  </p>
                  <p>
                    {box.gpaLat ? (
                      box.gpaLat + ","
                    ) : (
                      <span className="blank w20" />
                    )}{" "}
                    {box.gpsLong ? box.gpaLong : <span className="blank w20" />}
                  </p>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header>Relay Names</Card.Header>
                </Card.Content>
                <Card.Content extra>
                  <div className="card-row">
                    {box.relayName1 ? (
                      box.relayName1
                    ) : (
                      <span className="blank w50" />
                    )}
                    <Label horizontal className="pull-right">
                      1
                    </Label>
                  </div>
                  <div className="card-row">
                    {box.relayName2 ? (
                      box.relayName2
                    ) : (
                      <span className="blank w30" />
                    )}
                    <Label horizontal className="pull-right">
                      2
                    </Label>
                  </div>
                  {/* <div className="card-row">
										{box.relayName3 ? (
											box.relayName3
										) : (
											<span className="blank w40" />
										)}
										<Label horizontal className="pull-right">
											3
										</Label>
									</div>
									<div className="card-row">
										{box.relayName4 ? (
											box.relayName4
										) : (
											<span className="blank w30" />
										)}
										<Label horizontal className="pull-right">
											4
										</Label>
									</div>
									<div className="card-row">
										{box.relayName5 ? (
											box.relayName5
										) : (
											<span className="blank w50" />
										)}
										<Label horizontal className="pull-right">
											5
										</Label>
									</div>
									<div className="card-row">
										{box.relayName6 ? (
											box.relayName6
										) : (
											<span className="blank w30" />
										)}
										<Label horizontal className="pull-right">
											6
										</Label>
									</div>
									<div className="card-row">
										{box.relayName7 ? (
											box.relayName7
										) : (
											<span className="blank w40" />
										)}
										<Label horizontal className="pull-right">
											7
										</Label>
									</div>
									<div className="card-row">
										{box.relayName8 ? (
											box.relayName8
										) : (
											<span className="blank w50" />
										)}
										<Label horizontal className="pull-right">
											8
										</Label>
									</div>
									<div className="card-row">
										{box.relayName9 ? (
											box.relayName9
										) : (
											<span className="blank w40" />
										)}
										<Label horizontal className="pull-right">
											9
										</Label>
									</div>
									<div className="card-row">
										{box.relayName10 ? (
											box.relayName10
										) : (
											<span className="blank w30" />
										)}
										<Label horizontal className="pull-right">
											10
										</Label>
									</div>
									<div className="card-row">
										{box.relayName11 ? (
											box.relayName11
										) : (
											<span className="blank w30" />
										)}
										<Label horizontal className="pull-right">
											11
										</Label>
									</div>
									<div className="card-row">
										{box.relayName12 ? (
											box.relayName12
										) : (
											<span className="blank w40" />
										)}
										<Label horizontal className="pull-right">
											12
										</Label>
									</div>
									<div className="card-row">
										{box.relayName13 ? (
											box.relayName13
										) : (
											<span className="blank w50" />
										)}
										<Label horizontal className="pull-right">
											13
										</Label>
									</div>
									<div className="card-row">
										{box.relayName14 ? (
											box.relayName14
										) : (
											<span className="blank w30" />
										)}
										<Label horizontal className="pull-right">
											14
										</Label>
									</div>
									<div className="card-row">
										{box.relayName15 ? (
											box.relayName15
										) : (
											<span className="blank w50" />
										)}
										<Label horizontal className="pull-right">
											15
										</Label>
									</div>
									<div className="card-row">
										{box.relayName16 ? (
											box.relayName16
										) : (
											<span className="blank w40" />
										)}
										<Label horizontal className="pull-right">
											16
										</Label>
									</div> */}
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid>
        </Segment>
      </div>
    </div>
  ) : (
    <LoadingComponent />
  );
};

export default BoxDetails;
