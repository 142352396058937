import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
	background: #3c415e;
	box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.07);
	border: 1px solid #eee;
	border-radius: 5px;
	text-align: center;
	h2 {
		background: #262938;
		color: #fff;
		margin: 0;
		padding: 10px;
		font-size: 14px;
		font-weight: 300;
		text-transform: uppercase;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}
	p {
		margin: 0;
		color: #fff;
		font-size: 30px;
		padding: 20px;
	}
`;

const StatsCard = ({ title, value }) => {
	return (
		<Card>
			<p>{value}</p>
			<h2>{title}</h2>
		</Card>
	);
};

export default StatsCard;
