import { stripAppToken } from "../helpers";

export const formatPassData = (pass, tblClientProfileId) => {
  let passFieldsTemplate = {
    tblClientProfileId,
    tblBoxListId: 0,
    friendlyName: "",
    accessId: "",
    advertId: "",
    accessRelayNo1: true,
    accessRelayNo2: true,
    accessRelayNo3: true,
    accessRelayNo4: true,
    accessRelayNo5: true,
    accessRelayNo6: true,
    accessRelayNo7: true,
    accessRelayNo8: true,
    accessRelayNo9: true,
    accessRelayNo10: true,
    accessRelayNo11: true,
    accessRelayNo12: true,
    accessRelayNo13: true,
    accessRelayNo14: true,
    accessRelayNo15: true,
    accessRelayNo16: true,
    archived: false,
    dateFrom: null,
    dateTo: null,
    neverExpire: true,
    timeFrom: "1970-01-01T00:00:00+02:00",
    timeTo: "1970-01-01T23:59:59+02:00",
    useOncePerWindow: false,
  };

  let passFields = {
    ...passFieldsTemplate,
    ...pass,
  };
  // reformat App Token
  if (passFields.appToken) {
    passFields.appToken = stripAppToken(passFields.appToken).toString();
  }
  // format time strings
  if (passFields.dateFrom !== null && typeof passFields.dateFrom !== "string") {
    passFields.dateFrom = passFields.dateFrom.toISOString();
  }
  if (passFields.dateTo !== null && typeof passFields.dateTo !== "string") {
    passFields.dateTo = passFields.dateTo.toISOString();
  }
  if (typeof passFields.timeFrom !== "string") {
    passFields.timeFrom = passFields.timeFrom.toISOString();
  }
  if (typeof passFields.timeTo !== "string") {
    passFields.timeTo = passFields.timeTo.toISOString();
  }

  // reset dates if neverExpire = true
  if (passFields.neverExpire) {
    passFields.dateFrom = null;
    passFields.dateTo = null;
    passFields.useOncePerWindow = false;
  }

  return {
    ...passFields,
  };
};
