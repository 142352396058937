import React, {
  createContext,
  useContext,
  useEffect,
  useCallback,
  useState,
} from "react";
import { toastr } from "react-redux-toastr";
import { useApi } from "../api";
import { useAsync } from "../async";
import { removeItem, replaceOldItem } from "../helpers";
import { formatBoxData, formatBoxes } from "./formatting";
const BoxesContext = createContext({});
export const BoxesProvider = ({ children }) => {
  const { startAction, finishAction } = useAsync();
  const api = useApi();
  const [boxes, setBoxes] = useState();
  const updateList = (box) => replaceOldItem(box, boxes, setBoxes, "id");
  const removeFromList = (box) => removeItem(box, boxes, setBoxes, "id");

  const getBoxes = useCallback(() => {
    startAction();
    api.box
      .get()
      .then((res) => {
        finishAction();
        setBoxes(formatBoxes(res.data));
      })
      .catch((error) => {
        finishAction();
        console.error("AXIOS ERROR: ", error);
      });
  }, [api.box, finishAction, startAction]);

  useEffect(() => {
    console.log("get boxes");
    api.authorized ? getBoxes() : console.log("not authorized");
    const interval = setInterval(
      api.authorized ? getBoxes : () => console.log("not authorized"),
      api.refreshTime
    );
    return () => clearInterval(interval);
  }, [api, getBoxes]);

  const createBox = async (box) => {
    startAction();
    const newBox = formatBoxData(box);
    return await api.box
      .post(newBox)
      .then((res) => {
        finishAction();
        console.log(res.data);
        updateList({ ...res.data, accessPassCount: 0 });
        toastr.success("Success", `${newBox.friendlyName} created.`);
      })
      .catch((err) => {
        finishAction();
        console.log(err);
        toastr.error(
          "Oops",
          `We could not create the new box. Please try again.`
        );
      });
  };

  const updateBox = async (box) => {
    startAction();
    const updatedBox = formatBoxData(box);
    return await api.box
      .post(updatedBox)
      .then((res) => {
        finishAction();
        console.log(res.data);
        replaceOldItem(res.data, boxes, setBoxes, "id");
        toastr.success("Success", `${updatedBox.friendlyName} updated.`);
      })
      .catch((err) => {
        finishAction();
        console.log(err);
        toastr.error("Oops", `We could not update the box. Please try again.`);
      });
  };

  const deleteBox = (box) => {
    const message = `Are you sure you want to delete ${box.friendlyName}? This operation is irreversible. We recommend you unlink the box instead.`;

    toastr.confirm(message, {
      onOk: async () => {
        startAction();
        return await api.box
          .delete(box)
          .then((_) => {
            finishAction();
            removeFromList(box);
            toastr.success("Success", `${box.friendlyName} has been deleted.`);
          })
          .catch((err) => {
            finishAction();
            console.log(err);
            toastr.error(
              "Oops",
              "Something went wrong trying to delete this box, please try again."
            );
          });
      },
    });
  };

  const deLinkBox = async (box) => {
    box.tblclientprofile = null;
    startAction();
    return await api.box
      .post(box)
      .then((res) => {
        finishAction();
        updateList(res.data);
        toastr.success("Success", `${box.friendlyName} has been un-linked.`);
      })
      .catch((err) => {
        finishAction();
        console.log(err);
        toastr.error("Oops", "We could not un-link the box. Please try again.");
      });
  };

  return (
    <BoxesContext.Provider
      value={{ createBox, updateBox, deleteBox, deLinkBox, boxes }}
    >
      {children}
    </BoxesContext.Provider>
  );
};
export const BoxesConsumer = BoxesContext.Consumer;
export const useBoxes = () => useContext(BoxesContext);
