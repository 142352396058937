import React from "react";
import PageHeader from "../../app/layout/ui/header/PageHeader";
import SubHeader from "../../app/layout/ui/header/SubHeader";
import BoxesTable from "./BoxesTable";
import { useAuth } from "../../app/context/auth";

const Boxes = () => {
  const { role } = useAuth();

  return (
    <div>
      <PageHeader title="Boxes" />
      {role.isSuperAdmin ? (
        <SubHeader
          title="List of all ReKy Boxes"
          actionTo="boxes/create"
          actionTxt="Create Box"
          actionType="create"
        />
      ) : (
        <SubHeader title="List of all ReKy Boxes" />
      )}

      <BoxesTable />
    </div>
  );
};
export default Boxes;
