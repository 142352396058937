import React from "react";
import { Link } from "react-router-dom";
import { Grid, Divider, Icon, Table } from "semantic-ui-react";
import { useAuth } from "../../../app/context/auth";

const DashboardWelcomeWidget = () => {
  const { role } = useAuth();
  return (
    <div className="widget quick-links margin-bottom">
      <h2>Welcome to ReKy</h2>
      <Divider />
      <Grid>
        <Grid.Row columns={3}>
          <Grid.Column>
            <h3>Boxes</h3>
            <Table basic="very">
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <Link to="/boxes">
                      <Icon name="boxes" color="grey" />
                      View all boxes
                    </Link>
                  </Table.Cell>
                </Table.Row>
                {!role.isClientUser && !role.isClientAdmin && (
                  <Table.Row>
                    <Table.Cell>
                      <Link to="/boxes/create">
                        <Icon name="plus circle" color="grey" />
                        Create a new Box
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Grid.Column>
          <Grid.Column>
            <h3>Passes</h3>
            <Table basic="very">
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <Link to="/passes">
                      <Icon name="address card" color="grey" />
                      View all passes
                    </Link>
                  </Table.Cell>
                </Table.Row>
                {!role.isClientUser && (
                  <Table.Row>
                    <Table.Cell>
                      <Link to="/passes/create">
                        <Icon name="plus circle" color="grey" />
                        Create a new Pass
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Grid.Column>
          <Grid.Column>
            <h3>Quick Actions</h3>
            <Table basic="very">
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <Link to="/activities">
                      <Icon name="tasks" color="grey" />
                      View Latest Activities
                    </Link>
                  </Table.Cell>
                </Table.Row>
                {role.isSuperAdmin && (
                  <Table.Row>
                    <Table.Cell>
                      <Link to="/users">
                        <Icon name="user circle" color="grey" />
                        Manage Web Users
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
export default DashboardWelcomeWidget;
