import { createReducer } from '../../app/common/util/reducerUtil';
import { FETCH_ACTIVITY_STREAM } from './activitiesConstants';

const initialState = {};

export const fetchActivityStreamData = (state, payload = {}) => {
	return payload;
};

export default createReducer(initialState, {
	[FETCH_ACTIVITY_STREAM]: fetchActivityStreamData
});
