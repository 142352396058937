import React from "react";
import { Form, Label } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useMemo } from "react";

function DateSelector({
  input,
  label,
  defaultValue = new Date(),
  dateFormat,
  timeFormat,
  showTimeSelect,
  showTimeSelectOnly,
  timeIntervals,
  meta: { touched, error, form },
}) {
  return (
    <Form.Field error={touched && !!error}>
      {label && <label>{label}</label>}
      <DatePicker
        selected={input.value ? new Date(input.value) : new Date()}
        onChange={input.onChange}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        timeIntervals={timeIntervals}
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        timeCaption={label}
      />
      {touched && error && (
        <Label basic color="red">
          {error}
        </Label>
      )}
    </Form.Field>
  );
}

function init(input, defaultValue) {
  [input, defaultValue, null].filter((d) => isValidDate(new Date(d))).unshift();
  return [input, defaultValue, null].map(toTestTuple).find(([b]) => b)[1];
}

function toTestTuple(date) {
  return [isValidDate(new Date(date)), new Date(date)];
}
function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export default DateSelector;
