import { differenceInSeconds } from "date-fns";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { useAuth } from "../../app/context/auth";

export default function LogoutWarning() {
  const { showWarning, logout, expiry } = useAuth();
  const [distanceToExpiry, setDistanceToExpiry] = useState();
  useEffect(() => {
    console.log("interval");
    const interval = setInterval(() => {
      console.log("Tick");
      setDistanceToExpiry(differenceInSeconds(new Date(expiry), new Date()));
    }, 1000);

    return () => clearInterval(interval);
  }, [expiry]);
  useEffect(() => {
    if (distanceToExpiry <= 0) {
      logout();
    }
  }, [distanceToExpiry, logout]);
  return (
    <Modal open={showWarning}>
      <Modal.Content>
        <Modal.Description style={{ textAlign: "center" }}>
          <h1>You will be logged out in</h1>
          <h1>{distanceToExpiry}s</h1>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Log out now" onClick={logout} />
      </Modal.Actions>
    </Modal>
  );
}
