import React, { Component, useEffect, useState } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "./index.css";

export default function Table({
  numberOfRecords,
  columns,
  data,
  defaultPageSize,
  defaultSorted,
}) {
  const [pageSize, setPageSize] = useState(numberOfRecords);
  useEffect(() => setPageSize(numberOfRecords), [numberOfRecords]);

  return (
    <ReactTable
      columns={columns}
      data={data}
      filterable
      className="-striped -highlight"
      renderPageSizeOptions={() => <></>}
      defaultSorted={defaultSorted}
      {...(pageSize ? { pageSize } : {})}
      onPageSizeChange={(pageSize) => setPageSize(pageSize)}
    />
  );
}
