import React, { useCallback, useEffect } from "react";
import PageHeader from "../../app/layout/ui/header/PageHeader";
import Message from "../../app/common/message";
import ActivityTable from "./ActivityTable";
import { useState } from "react";
import { useActivities } from "../../app/context/activities";
import { useApi } from "../../app/context/api";

const Activities = () => {
  const [numberOfRecords, setNumberOfRecords] = useState(50);
  const [activities, setActivities] = useState();
  const { getActivities } = useActivities();
  const api = useApi();

  const fetchActivities = useCallback(
    (noReset) => {
      if (!noReset) {
        setActivities(null);
      }
      api.authorized
        ? getActivities(numberOfRecords, setActivities)
        : console.log("not authorized");
    },
    [api, getActivities, numberOfRecords]
  );

  useEffect(() => {
    fetchActivities();
  }, [fetchActivities, numberOfRecords]);

  useEffect(() => {
    const interval = setInterval(() => fetchActivities(true), 10000);
    return () => {
      clearInterval(interval);
    };
  }, [fetchActivities]);

  return (
    <div>
      <PageHeader title="Activity Stream" />
      <Message
        content={`Number of latest records to fetch`}
        type="info"
        changeNumberOfRecords={setNumberOfRecords}
        numberOfRecords={numberOfRecords}
        fetchLatest={fetchActivities}
      />
      <div className="page-wrapper">
        <ActivityTable
          activities={activities}
          numberOfRecords={numberOfRecords}
        />
      </div>
    </div>
  );
};
export default Activities;
