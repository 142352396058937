import React from 'react';
import { Form, Label, Checkbox } from 'semantic-ui-react';

const CheckboxSwitch = ({
	input,
	label,
	align,
	defaultChecked,
	meta: { touched, error }
}) => {
	delete input.value;
	return (
		<Form.Field error={touched && !!error} className={align}>
			{label && <label>{label}</label>}

			<Checkbox {...input} defaultChecked={defaultChecked} toggle />

			{touched && error && (
				<Label basic color="red">
					{error}
				</Label>
			)}
		</Form.Field>
	);
};

export default CheckboxSwitch;
