import React from "react";
import PageHeader from "../../app/layout/ui/header/PageHeader";

import SubHeader from "../../app/layout/ui/header/SubHeader";
import PassesTable from "./PassesTable";

const Passes = () => {
  return (
    <div>
      <PageHeader title="Passes" />
      <SubHeader
        title="List of all ReKy Passes"
        actionTo="/passes/create"
        actionTxt="Create Access Pass"
        actionType="create"
      />

      <div className="page-wrapper">
        <PassesTable />
      </div>
    </div>
  );
};
export default Passes;
