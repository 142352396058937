import React, { createContext, useCallback, useContext, useState } from "react";

const AsyncContext = createContext({});
export const AsyncProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const startAction = useCallback(() => setLoading(true), []);
  const finishAction = useCallback(() => setLoading(false), []);

  return (
    <AsyncContext.Provider value={{ loading, startAction, finishAction }}>
      {children}
    </AsyncContext.Provider>
  );
};
export const AsyncConsumer = AsyncContext.Consumer;
export const useAsync = () => useContext(AsyncContext);
