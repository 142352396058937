import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { toastr } from "react-redux-toastr";
import { useApi } from "../api";
import { useBoxes } from "../boxes";
import { removeItem, replaceOldItem } from "../helpers";
import { formatPassData } from "./formatting";
const PassesContext = createContext({});
export const PassesProvider = ({ children }) => {
  const api = useApi();
  const { boxes } = useBoxes();
  const [passes, setPasses] = useState();
  const [boxPasses, setBoxPasses] = useState();
  const updateList = (pass) => replaceOldItem(pass, passes, setPasses, "id");
  const removeFromList = (pass) => removeItem(pass, passes, setPasses, "id");
  const updateBoxList = (pass) =>
    replaceOldItem(pass, boxPasses, setBoxPasses, "id");
  const removeFromBoxList = (pass) =>
    removeItem(pass, boxPasses, setBoxPasses, "id");

  const clearBoxPasses = useCallback(() => setBoxPasses(null), []);

  const getPasses = useCallback(() => {
    api.pass
      .get()
      .then((res) => {
        setPasses(res.data);
      })
      .catch((error) => {
        console.error("AXIOS ERROR: ", error);
      });
  }, [api, setPasses]);

  useEffect(() => {
    api.authorized ? getPasses() : console.log("not authorized");
    const interval = setInterval(
      api.authorized ? getPasses : () => console.log("not authorized"),
      api.refreshTime
    );
    return () => clearInterval(interval);
  }, [api, getPasses]);

  const getBoxesPasses = useCallback(
    (box) => {
      return api.pass
        .getByBox(box)
        .then((res) => {
          setBoxPasses(res.data);
        })
        .catch((error) => {
          console.error("AXIOS ERROR: ", error);
        });
    },
    [api]
  );

  const createPass = async (pass) => {
    const box = boxes.find((box) => box.id === pass.tblBoxListId);
    debugger;
    const newPass = formatPassData(pass, box.tblclientprofile);
    debugger;
    return api.pass
      .post(newPass)
      .then((res) => {
        updateList(res.data);
        toastr.success("Success", `New Pass created.`);
      })
      .catch((err) => {
        console.log(err);
        toastr.error(
          "Oops",
          `We could not create the new pass. Please try again.`
        );
      });
  };

  const updatePass = async (pass, tblClientProfileId) => {
    const updatedPass = formatPassData(pass, tblClientProfileId);
    api.pass
      .post(updatedPass)
      .then((res) => {
        updateList(res.data);
        toastr.success("Success", `Pass updated.`);
      })
      .catch((err) => {
        console.log(err);
        toastr.error("Oops", `We could not update the pass. Please try again.`);
      });
  };

  const deletePass = (pass) => {
    const message = `Are you sure you want to delete ${pass.id}? This operation is irreversible.`;

    toastr.confirm(message, {
      onOk: () => {
        api.pass
          .delete(pass)
          .then((_) => {
            removeFromList(pass);
            if (boxPasses) {
              removeFromBoxList(pass);
            }
            toastr.success("Success", `${pass.id} has been deleted.`);
          })
          .catch((err) => {
            console.log(err);
            toastr.error(
              "Oops",
              "Something went wrong trying to delete this pass, please try again."
            );
          });
      },
    });
  };

  const deLinkAppUser = async (pass) => {
    pass.tblAppUserId = 0;

    api.pass
      .post(pass)
      .then((res) => {
        updateList(res.data);
        updateBoxList(res.data);
        toastr.success("Success", `App User de-linked.`);
      })
      .catch((err) => {
        console.log(err);
        toastr.error(
          "Oops",
          "We could not de-link the App User. Please try again."
        );
      });
  };

  const resetPassExpiry = (pass) => {
    // force 'expired' = false to reactivate it
    pass.expired = false;

    api.pass
      .post(pass)
      .then((res) => {
        updateList(res.data);
        updateBoxList(res.data);
        toastr.success("Success", `Pass expiry successfully reset.`);
      })
      .catch((err) => {
        console.log(err);
        toastr.error(
          "Oops",
          "We could not reset the pass expiry. Please try again."
        );
      });
  };
  return (
    <PassesContext.Provider
      value={{
        passes,
        boxPasses,
        clearBoxPasses,
        getBoxesPasses,
        createPass,
        updatePass,
        deletePass,
        deLinkAppUser,
        resetPassExpiry,
      }}
    >
      {children}
    </PassesContext.Provider>
  );
};
export const PassesConsumer = PassesContext.Consumer;
export const usePasses = () => useContext(PassesContext);
