import React, { Component } from "react";
import { faInfoCircle } from "../../../app/layout/ui/icons/";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Message extends Component {
  render() {
    const {
      content,
      type,
      changeNumberOfRecords,
      numberOfRecords,
      fetchLatest
    } = this.props;
    const classes = ["message-box", type, `list-${numberOfRecords}`].join(" ");
    return (
      <div className={classes}>
        <div>
          {type === "info" ? <FontAwesomeIcon icon={faInfoCircle} /> : null}
          {content}
        </div>
        <div className="header-controls">
          {/* <button className="btn-10" onClick={() => changeNumberOfRecords(10)}>
            10
          </button> */}
          <button className="btn-20" onClick={() => changeNumberOfRecords(20)}>
            20
          </button>
          <button className="btn-50" onClick={() => changeNumberOfRecords(50)}>
            50
          </button>
          <button
            className="btn-100"
            onClick={() => changeNumberOfRecords(100)}
          >
            100
          </button>
          <button className="btn-latest" onClick={() => fetchLatest()}>
            Fetch Latest
          </button>
        </div>
      </div>
    );
  }
}
export default Message;
