import React from "react";
import NavItem from "./navItem/NavItem";
import * as icons from "../../../ui/icons/";
import { useMemo } from "react";
import { useAuth } from "../../../../context/auth";
import { endpoint } from "../../../../config";
import styled from "styled-components";

const NavItems = ({ visible, toggle }) => {
  const { role } = useAuth();
  const classes = useMemo(() => {
    if (visible) {
      return ["main-nav", "open"];
    } else return ["main-nav", "closed"];
  }, [visible]);

  return (
    <nav className={classes.join(" ")}>
      {endpoint.includes("staging") && <StagingFlag>STAGING</StagingFlag>}
      <NavItem
        title="Dashboard"
        to="/dashboard"
        icon={icons.faTachometer}
        toggle={toggle}
      />
      {role.isSuperAdmin && (
        <NavItem
          title="Clients"
          to="/clients"
          icon={icons.faFileUser}
          toggle={toggle}
        />
      )}
      <NavItem
        title="Boxes"
        to="/boxes"
        icon={icons.faBoxesAlt}
        toggle={toggle}
      />
      <NavItem
        title="Passes"
        to="/passes"
        icon={icons.faAddressCard}
        toggle={toggle}
      />
      <NavItem
        title="Activity Stream"
        to="/activities"
        icon={icons.faTasks}
        toggle={toggle}
      />
      {role.isSuperAdmin && (
        <div>
          <div className="admin-only-label">Admin Only</div>
          <NavItem
            title="Web Users"
            to="/users"
            icon={icons.faUserCircle}
            toggle={toggle}
          />
        </div>
      )}
    </nav>
  );
};
export default NavItems;

const StagingFlag = styled.div`
  padding: 10px 15px;
  font-size: 1.2rem;
  text-align: center;
  background: #2a356f;
`;
