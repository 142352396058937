import React from "react";
import { useMemo } from "react";
import { Form, Label, Dropdown } from "semantic-ui-react";
import { useClients } from "../../context/clients";

const CompanySelect = ({
  name,
  input,
  multiple,
  label,
  disabled,
  meta: { touched, error },
}) => {
  const { clients } = useClients();
  const clientsRefOptions = useMemo(() => {
    if (clients) {
      return clients.map((client) => ({
        key: client.tblClientProfile.id,
        text: client.tblClientProfile.clientName,
        value: client.tblClientProfile.id,
      }));
    }
  }, [clients]);

  return (
    <Form.Field error={touched && !!error} autoComplete="off">
      {label && <label>{label}</label>}
      <Dropdown
        loading={!clients}
        name={name}
        defaultValue={input.value || null}
        onChange={(e, data) => input.onChange(data.value)}
        placeholder="-- Select A Client --"
        options={clientsRefOptions}
        multiple={multiple}
        search
        selection
        autoComplete="off"
        disabled={disabled}
      />

      {touched && error && (
        <Label basic color="red">
          {error}
        </Label>
      )}
    </Form.Field>
  );
};

export default CompanySelect;
