import React, { createContext, useContext } from "react";
import { useApi } from "../api";

const ActivitiesContext = createContext({});
export const ActivitiesProvider = ({ children }) => {
  const api = useApi();
  const getActivities = (numberOfRecords, setItems) => {
    return api.activity
      .get(numberOfRecords)
      .then((res) => {
        setItems(res.data);
      })
      .catch((error) => {
        console.error("AXIOS ERROR: ", error);
      });
  };

  return (
    <ActivitiesContext.Provider value={{ getActivities }}>
      {children}
    </ActivitiesContext.Provider>
  );
};
export const ActivitiesConsumer = ActivitiesContext.Consumer;
export const useActivities = () => useContext(ActivitiesContext);
