import React from "react";
import { useMemo } from "react";
import { Form, Label, Dropdown } from "semantic-ui-react";
import { useBoxes } from "../../context/boxes";

const BoxSelect = ({
  name,
  input,
  multiple,
  label,
  disabled,
  meta: { touched, error },
}) => {
  const { boxes } = useBoxes();
  const boxesRefOptions = useMemo(() => {
    if (boxes) {
      return boxes.map((box) => ({
        key: box.id,
        text: box.friendlyName,
        value: box.id,
      }));
    }
  }, [boxes]);

  return (
    <Form.Field error={touched && !!error} autoComplete="off">
      {label && <label>{label}</label>}
      <Dropdown
        loading={!boxes}
        name={name}
        defaultValue={input.value || null}
        onChange={(e, data) => input.onChange(data.value)}
        placeholder="-- Select A Box --"
        options={boxesRefOptions}
        multiple={multiple}
        search
        selection
        autoComplete="off"
        disabled={disabled}
      />

      {touched && error && (
        <Label basic color="red">
          {error}
        </Label>
      )}
    </Form.Field>
  );
};

export default BoxSelect;
