// ========================
//        Helpers
//=========================

function hasTblClientProfile(tblBoxList) {
  return tblBoxList.tblclientprofile && tblBoxList.tblclientprofile !== null;
}

function friendlyName(tblBoxList) {
  return `${tblBoxList.tblclientprofile} - ${tblBoxList.locName} - ${
    tblBoxList.btMac
  }`;
}

function hasLowBattery(lowBatteryFlag) {
  return lowBatteryFlag === true;
}

export { hasTblClientProfile, friendlyName, hasLowBattery };
