// FontAwesome Library
import { library } from '@fortawesome/fontawesome-svg-core';
import {} from '@fortawesome/pro-regular-svg-icons';
import {} from '@fortawesome/pro-solid-svg-icons';
import {
	faLockAlt,
	faBoxesAlt,
	faUserCircle,
	faFileUser,
	faTachometer,
	faTasks,
	faPassport,
	faAddressCard,
	faPlusCircle,
	faInfoCircle,
	faEdit
} from '@fortawesome/pro-light-svg-icons';
library.add(
	faLockAlt,
	faBoxesAlt,
	faUserCircle,
	faFileUser,
	faTachometer,
	faTasks,
	faPassport,
	faAddressCard,
	faPlusCircle,
	faInfoCircle,
	faEdit
);
export {
	faLockAlt,
	faBoxesAlt,
	faUserCircle,
	faFileUser,
	faTachometer,
	faTasks,
	faPassport,
	faAddressCard,
	faPlusCircle,
	faInfoCircle,
	faEdit
};
