import React, { useMemo } from "react";
import PageHeader from "../../../app/layout/ui/header/PageHeader";
import SubHeader from "../../../app/layout/ui/header/SubHeader";
import PassForm from "../form";
import { usePasses } from "../../../app/context/passes";
import { useBoxes } from "../../../app/context/boxes";
import { useParams } from "react-router-dom";
import { Segment } from "semantic-ui-react";

const EditPass = () => {
  const { passes } = usePasses();
  const { boxes } = useBoxes();
  const params = useParams();
  const pass = useMemo(() => {
    if (passes) return passes.find((p) => p.id === parseInt(params.id));
  }, [params, passes]);
  const box = useMemo(() => {
    if (boxes && pass)
      return params.boxId
        ? boxes?.find((p) => p.id === parseInt(params.boxId))
        : boxes?.find((p) => p.id === pass.tblBoxListId);
  }, [params, boxes, pass]);
  return (
    <div>
      <PageHeader title="Edit Pass" />
      <SubHeader title="Edit an existing ReKy Pass" />

      <div className="page-wrapper">
        <Segment basic loading={!pass}>
          <PassForm isNew={false} pass={pass} box={box} />
        </Segment>
      </div>
    </div>
  );
};

export default EditPass;
