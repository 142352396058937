export const formatBoxData = (box) => {
  let boxFieldsTemplate = {
    btMac: "",
    secondaryGuid: "",
    friendlyName: "",
    address1: "",
    address2: "",
    address3: "",
    address4: "",
    boxtype: 0,
    gpsLat: 0,
    gpsLong: 0,
    idleSetting: true,
    locName: "",
    lowBatteryFlag: false,
    postalCode: "",
    pulseLength: 1000,
    relayName1: "",
    relayName2: "",
    relayName3: "",
    relayName4: "",
    relayName5: "",
    relayName6: "",
    relayName7: "",
    relayName8: "",
    relayName9: "",
    relayName10: "",
    relayName11: "",
    relayName12: "",
    relayName13: "",
    relayName14: "",
    relayName15: "",
    relayName16: "",
  };
  let boxFields = {
    ...boxFieldsTemplate,
    ...box,
  };

  return {
    ...boxFields,
  };
};

export const formatBoxes = (boxes) => {
  return boxes.reduce((arr, box) => {
    return (arr = [...arr, { ...box.tblBoxList, ...box }]);
  }, []);
};
