import React from "react";

import "../../index.css";
import { DataProvider } from "../context/data";

import { AuthProvider } from "../context/auth";
import Router from "./Router";
import { ApiProvider } from "../context/api";
import { AsyncProvider } from "../context/async";

const App = () => {
  return (
    <AsyncProvider>
      <ApiProvider>
        <AuthProvider>
          <DataProvider>
            <Router />
          </DataProvider>
        </AuthProvider>
      </ApiProvider>
    </AsyncProvider>
  );
};

export default App;
