// Display as 16 chars with '-' I.E. 0000-0000-0000-0000
export function formatAppToken(token) {
  const pad = "0";
  let fullToken = "";
  const sToken = String(token);

  // Pad with leading '0' if too short
  if (sToken.length === 15) {
    fullToken = `${pad}${sToken}`;
  } else {
    fullToken = `${sToken}`;
  }

  // Format for display
  const formattedToken = fullToken.match(/.{1,4}/g).join("-");

  return formattedToken;
}

// Strip any '-' out of AppToken to send back to API
export function stripAppToken(token) {
  let formattedToken = "";
  const sToken = String(token);

  if (sToken.includes("-")) {
    formattedToken = sToken.replace(/-/g, "");
  } else {
    formattedToken = sToken;
  }
  /* global BigInt */
  return BigInt(formattedToken);
}

export const replaceOldItem = (item, items, setItems, compareVal) => {
  const filteredList = items.filter((i) => i[compareVal] !== item[compareVal]);
  const oldItem = items.find((i) => i[compareVal] === item[compareVal]);
  if (oldItem) {
    setItems([...filteredList, { ...oldItem, ...item }]);
  } else {
    setItems([...filteredList, item]);
  }
};
export const removeItem = (item, items, setItems, compareVal) => {
  const filteredList = items.filter((i) => i[compareVal] !== item[compareVal]);
  setItems(filteredList);
};
