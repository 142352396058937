import React, { useMemo } from "react";
import { Grid, Card, Icon, Label, Image, Segment } from "semantic-ui-react";
import PageHeader from "../../../app/layout/ui/header/PageHeader";
import BoxesTableList from "../../boxes/boxesListTable";
import SubHeader from "../../../app/layout/ui/header/SubHeader";
import { useParams } from "react-router-dom";
import { useClients } from "../../../app/context/clients";
import { useBoxes } from "../../../app/context/boxes";

const ClientDetails = () => {
  const { clients } = useClients();
  const params = useParams();
  const client = useMemo(() => {
    console.log(params);
    if (clients)
      return clients?.find((client) => client.id === parseInt(params.id));
  }, [clients, params]);
  const { boxes } = useBoxes();
  const clientBoxes = useMemo(() => {
    if (client && boxes)
      return boxes.filter((b) => b.tblclientprofile === client.id);
  }, [boxes, client]);

  return (
    <div>
      <PageHeader title="Client Details" />
      <SubHeader
        title={`${client.clientName} details`}
        actionTo={`/clients/client/${client.id}`}
        actionTxt="Edit Client"
        actionType="edit"
      />

      <div className="page-wrapper">
        <Segment basic loading={!client}>
          <Grid>
            <Grid.Column>
              <div className="detail-page-count-header two-col">
                <div className="col">
                  <div className="title">
                    <Icon name="address card" /> Access Passes
                  </div>
                  <div className="value">{client.accessPassCount}</div>
                </div>
                <div className="col">
                  <div className="title">
                    <Icon name="boxes" /> Boxes
                  </div>
                  <div className="value">{client.rekyBoxCount}</div>
                </div>
              </div>

              <h2 className="in-page-heading">Boxes</h2>
              {clientBoxes && clientBoxes.length > 0 ? (
                <BoxesTableList boxes={clientBoxes} />
              ) : (
                <div className="no-results">
                  No boxes found linked to {client.clientName}.
                </div>
              )}
            </Grid.Column>
          </Grid>
          <h2 className="in-page-heading">Client Details</h2>
          <Grid columns={3}>
            <Grid.Column>
              <Card fluid>
                {client.logoPath && <Image src={client.logoPath} />}
                <Card.Content>
                  <Card.Header>
                    {client.clientName}
                    <Label horizontal className="pull-right">
                      CLIENT
                    </Label>
                  </Card.Header>
                </Card.Content>
                {client.regNo && (
                  <Card.Content extra>
                    {client.regNo}
                    <Label horizontal className="pull-right">
                      REG. NO.
                    </Label>
                  </Card.Content>
                )}
                {client.taxNo && (
                  <Card.Content extra>
                    {client.taxNo}
                    <Label horizontal className="pull-right">
                      TAX. NO.
                    </Label>
                  </Card.Content>
                )}
                <Card.Content extra>
                  {client.billingType}
                  <Label horizontal className="pull-right">
                    BILLING TYPE
                  </Label>
                </Card.Content>
              </Card>

              {client.primaryContactFirstName && (
                <Card fluid>
                  <Card.Content>
                    <Card.Header>Primary Contact</Card.Header>
                  </Card.Content>
                  <Card.Content extra>
                    <h4>Name:</h4>
                    <p>
                      {client.primaryContactTitle}{" "}
                      {client.primaryContactFirstName}{" "}
                      {client.primaryContactLastName}
                    </p>
                  </Card.Content>
                  <Card.Content extra>
                    <h4>Contact:</h4>
                    {client.primaryContactMobile && (
                      <p>
                        <Icon name="mobile alternate" />{" "}
                        {client.primaryContactMobile}
                      </p>
                    )}
                    {client.primaryContactNo && (
                      <p>
                        <Icon name="phone" /> {client.primaryContactNo}
                      </p>
                    )}
                    {client.billingEmail && (
                      <p>
                        <Icon name="mail" /> {client.billingEmail}
                      </p>
                    )}
                  </Card.Content>
                </Card>
              )}
            </Grid.Column>
            <Grid.Column>
              {client.billingAddress1 && (
                <Card fluid>
                  <Card.Content>
                    <Card.Header>Billing</Card.Header>
                  </Card.Content>
                  {client.billingName && (
                    <Card.Content extra>
                      <h4>Name:</h4>
                      <p>{client.billingName}</p>
                    </Card.Content>
                  )}
                  {client.billingAddress1 && (
                    <Card.Content extra>
                      <h4>Address:</h4>
                      <p>{client.billingAddress1}</p>
                      <p>{client.billingAddress2}</p>
                      <p>{client.billingAddress3}</p>
                      <p>{client.billingAddress4}</p>
                      <p>{client.billingAddress5}</p>
                      <p>{client.billingPostalCode}</p>
                    </Card.Content>
                  )}
                  <Card.Content extra>
                    <h4>Contact:</h4>
                    {client.billingContactFirstName && (
                      <p>
                        {client.billingContactFirstName}{" "}
                        {client.billingContactLastName}
                      </p>
                    )}
                    {client.billingContactMobile && (
                      <p>
                        <Icon name="mobile alternate" />
                        {client.billingContactMobile}
                      </p>
                    )}
                    {client.billingContactNo && (
                      <p>
                        <Icon name="phone" /> {client.billingContactNo}
                      </p>
                    )}
                    {client.billingEmail && (
                      <p>
                        <Icon name="mail" /> {client.billingEmail}
                      </p>
                    )}
                  </Card.Content>
                </Card>
              )}
            </Grid.Column>
            <Grid.Column>
              {client.shippingAddress1 && (
                <Card fluid>
                  <Card.Content>
                    <Card.Header>Shipping</Card.Header>
                  </Card.Content>
                  <Card.Content extra>
                    <h4>Address:</h4>
                    <p>{client.shippingAddress1}</p>
                    <p>{client.shippingAddress2}</p>
                    <p>{client.shippingAddress3}</p>
                    <p>{client.shippingAddress4}</p>
                    <p>{client.shippingAddress5}</p>
                    <p>{client.shipToPostalCode}</p>
                  </Card.Content>
                </Card>
              )}
            </Grid.Column>
          </Grid>
        </Segment>
      </div>
    </div>
  );
};
export default ClientDetails;
