import { combineReducers } from 'redux';
import { reducer as FormReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import asyncReducer from '../../app/async/asyncReducer';
import authReducer from '../../features/login/authReducer';
import activitiesReducer from '../../features/activities/activitiesReducer';
import boxesReducer from '../../features/boxes/boxesReducer';
import clientsReducer from '../../features/clients/clientsReducer';
import usersReducer from '../../features/users/usersReducer';
import passesReducer from '../../features/passes/passesReducer';
import dashboardReducer from '../../features/dashboard/dashboardReducer';

const rootReducer = combineReducers({
	form: FormReducer,
	async: asyncReducer,
	toastr: toastrReducer,
	webuser: authReducer,
	activities: activitiesReducer,
	boxes: boxesReducer,
	clients: clientsReducer,
	users: usersReducer,
	passes: passesReducer,
	dashboard: dashboardReducer
});

export default rootReducer;
