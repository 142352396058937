import React from 'react';
import { Form, Label, Dropdown } from 'semantic-ui-react';

const DropdownInput = ({
	name,
	input,
	width,
	defaultValue,
	multiple,
	placeholder,
	label,
	options,
	meta: { touched, error }
}) => {
	return (
		<Form.Field error={touched && !!error} autoComplete="off" width={width}>
			{label && <label>{label}</label>}
			<Dropdown
				name={name}
				defaultValue={input.value !== '' ? input.value : defaultValue}
				onChange={(e, data) => input.onChange(data.value)}
				placeholder={placeholder}
				options={options}
				multiple={multiple}
				search
				selection
				autoComplete="off"
			/>

			{touched &&
				error && (
					<Label basic color="red">
						{error}
					</Label>
				)}
		</Form.Field>
	);
};

export default DropdownInput;
