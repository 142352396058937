import React from "react";
import { connect } from "react-redux";
import { Form, Button } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import "./login.css";
import { combineValidators, isRequired } from "revalidate";
import AuthTextInput from "../../app/common/form/AuthTextInput";
import release from "../../app/config/release.json";
import { useAuth } from "../../app/context/auth";
import { useAsync } from "../../app/context/async";
import { endpoint } from "../../app/config";

const mapState = (state) => ({
  formStatus: state.form.loginForm,
});

const validate = combineValidators({
  username: isRequired({ message: "Username is required" }),
  password: isRequired({ message: "Password is required" }),
});

const LoginPage = ({ handleSubmit, error, invalid, submitting, pristine }) => {
  const { login } = useAuth();
  const { loading } = useAsync();
  return (
    <div className="login-page-container">
      <div className="login-page">
        <div className="login-box">
          <div className="login-logo">
            <img src="/assets/reky-logo.svg" alt="ReKy Web Portal" />
          </div>
          <h2>Login to ReKy</h2>
          {error && <div className="loginErrorMessage">{error}</div>}
          <Form size="large" onSubmit={handleSubmit(login)}>
            <Field
              label="Your Username"
              name="username"
              component={AuthTextInput}
              type="text"
              placeholder="Enter your username"
            />
            <Field
              label="Your Password"
              name="password"
              component={AuthTextInput}
              type="password"
              placeholder="********"
            />
            <Button
              loading={loading}
              disabled={invalid || submitting || pristine || loading}
              fluid
              size="large"
              className="button-login"
            >
              LOGIN
            </Button>
          </Form>
        </div>

        <div className="login-box-footer">
          <p>
            &copy;{" "}
            <a
              href="https://myreky.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              ReKy&trade;
            </a>{" "}
            {" - "} All Rights Reserved
          </p>
          <div className="version login">
            {release.version} {endpoint.includes("staging") && "- Staging"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapState)(
  reduxForm({ form: "loginForm", enableReinitialize: true, validate })(
    LoginPage
  )
);
