import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm, Field } from "redux-form";
import { useHistory, withRouter } from "react-router-dom";
import { Segment, Form, Button, Header, Divider } from "semantic-ui-react";
import {
  composeValidators,
  combineValidators,
  isRequired,
  hasLengthGreaterThan,
  hasLengthLessThan,
  createValidator,
} from "revalidate";
import TextInput from "../../../app/common/form/TextInput";
import DropdownInput from "../../../app/common/form/DropDownInput";
import { useClients } from "../../../app/context/clients";
import { useAsync } from "../../../app/context/async";

const mapState = (state, ownProps) => {
  const client = ownProps.client ? ownProps.client : {};

  return {
    client,
    initialValues: client,
  };
};

const billingTypeOptions = [
  { key: 0, text: "Type 1", value: 0 },
  { key: 1, text: "Type 2", value: 1 },
  { key: 2, text: "Type 3", value: 2 },
  { key: 3, text: "Type 4", value: 3 },
  { key: 4, text: "Type 5", value: 4 },
  { key: 5, text: "Type 6", value: 5 },
  { key: 6, text: "Type 7", value: 6 },
  { key: 7, text: "Type 8", value: 7 },
  { key: 8, text: "Type 9", value: 8 },
  { key: 9, text: "Type 10", value: 9 },
];

const deactivateTypeOptions = [
  { key: false, text: "Active Client", value: false },
  { key: true, text: "Deactivated Client", value: true },
];

const isValidEmail = createValidator(
  (message) => (value) => {
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(value)) {
      return message;
    }
  },
  "Invalid email address"
);

const validate = combineValidators({
  clientName: composeValidators(
    isRequired({ message: "Client Name is required" }),
    hasLengthGreaterThan(4)({
      message: "Client Name must be longer than 5 characters.",
    }),
    hasLengthLessThan(46)({
      message: "Client Name must be shorter than 45 characters.",
    })
  )(),
  billingName: hasLengthLessThan(46)({
    message: "Billing Name must be shorter than 45 characters.",
  }),
  taxNo: hasLengthLessThan(17)({
    message: "Tax Number must be shorter than 16 characters.",
  }),
  regNo: hasLengthLessThan(17)({
    message: "Registration Number must be shorter than 16 characters.",
  }),
  billingEmail: composeValidators(
    hasLengthLessThan(81)({
      message: "Email must be shorter than 80 characters.",
    }),
    isValidEmail
  )(),
  primaryContactEmail: composeValidators(
    hasLengthLessThan(81)({
      message: "Email must be shorter than 80 characters.",
    }),
    isValidEmail
  )(),
});

const ClientsForm = ({
  invalid,
  submitting,
  pristine,
  isNew,
  initialValues,
  handleSubmit,
}) => {
  const { loading } = useAsync();
  const { updateClient, createClient } = useClients();
  const history = useHistory();
  const onFormSubmit = (values) => {
    if (initialValues.id) {
      updateClient(values);
    } else {
      createClient(values);
      history.push("/clients");
    }
  };

  return (
    <Segment loading={loading}>
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Form.Group widths="equal">
          <Field
            name="clientName"
            type="text"
            label="Client Name"
            component={TextInput}
            placeholder="Enter Client Name"
          />
          <Field
            name="taxNo"
            type="text"
            label="Tax Number"
            component={TextInput}
            placeholder="Enter Tax Number"
          />
          <Field
            name="regNo"
            type="text"
            label="Registration Number"
            component={TextInput}
            placeholder="Enter Registration Number"
          />
        </Form.Group>

        <Header sub color="blue" content="Client Details" />
        <Divider />
        <Form.Group widths="equal">
          <Field
            name="billingType"
            type="text"
            label="Billing Type"
            component={DropdownInput}
            options={billingTypeOptions}
            defaultValue={0}
          />
          <Field
            name="deactivateFlag"
            type="text"
            label="Client Status"
            component={DropdownInput}
            options={deactivateTypeOptions}
            defaultValue={false}
          />
          <Field
            name="logoPath"
            type="text"
            label="Logo Path"
            component={TextInput}
            placeholder="http://..."
          />
        </Form.Group>

        <Header sub color="blue" content="Billing Details" />
        <Divider />
        <Form.Group widths="equal">
          <Field
            name="billingName"
            type="text"
            label="Billing Client Name"
            component={TextInput}
            placeholder="Enter Client Billing Name"
          />
          <Field
            name="billingContactFirstName"
            type="text"
            label="Billing Contact First Name"
            component={TextInput}
            placeholder="Enter Name"
          />
          <Field
            name="billingContactLastName"
            type="text"
            label="Billing Contact Last Name"
            component={TextInput}
            placeholder="Enter Last Name"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Field
            name="billingContactNo"
            type="text"
            label="Billing Contact Number"
            component={TextInput}
            placeholder="(000) 000-00000"
          />
          <Field
            name="billingContactMobile"
            type="text"
            label="Billing Contact Mobile"
            component={TextInput}
            placeholder="(000) 000-0000"
          />
          <Field
            name="billingEmail"
            type="email"
            label="Billing Contact Email"
            component={TextInput}
            placeholder="name@domain.com"
          />
        </Form.Group>

        <Header sub color="blue" content="Primary Contact Details" />
        <Divider />
        <Form.Group widths="equal">
          <Field
            name="primaryContactTitle"
            type="text"
            label="Primary Contact Title"
            component={TextInput}
            placeholder="Mr. / Mrs. / Miss."
          />
          <Field
            name="primaryContactFirstName"
            type="text"
            label="Primary Contact First Name"
            component={TextInput}
            placeholder="Enter Name"
          />
          <Field
            name="primaryContactLastName"
            type="text"
            label="Primary Contact Last Name"
            component={TextInput}
            placeholder="Enter Last Name"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Field
            name="primaryContactNo"
            type="text"
            label="Primary Contact Number"
            component={TextInput}
            placeholder="(000) 000-00000"
          />
          <Field
            name="primaryContactMobile"
            type="text"
            label="Primary Contact Mobile"
            component={TextInput}
            placeholder="(000) 000-0000"
          />
          <Field
            name="primaryContactEmail"
            type="email"
            label="Primary Contact Email"
            component={TextInput}
            placeholder="name@domain.com"
          />
        </Form.Group>

        <Header sub color="blue" content="Billing Address" />
        <Divider />
        <Form.Group widths="equal">
          <Field
            name="billingAddress1"
            type="text"
            label="Billing Address Line 1"
            component={TextInput}
            placeholder="Enter Billing Address Line 1"
          />
          <Field
            name="billingAddress2"
            type="text"
            label="Billing Address Line 2"
            component={TextInput}
            placeholder="Enter Billing Address Line 2"
          />
          <Field
            name="billingAddress3"
            type="text"
            label="Billing Address Line 3"
            component={TextInput}
            placeholder="Enter Billing Address Line 3"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Field
            name="billingAddress4"
            type="text"
            label="Billing Address Line 4"
            component={TextInput}
            placeholder="Enter Billing Address Line 4"
          />
          <Field
            name="billingAddress5"
            type="text"
            label="Billing Address Line 5"
            component={TextInput}
            placeholder="Enter Billing Address Line 5"
          />
          <Field
            name="billingPostalCode"
            type="text"
            label="Billing Postal Code"
            component={TextInput}
            placeholder="Enter Billing Postal Code"
          />
        </Form.Group>

        <Header sub color="blue" content="Shipping Address" />
        <Divider />
        <Form.Group widths="equal">
          <Field
            name="shippingAddress1"
            type="text"
            label="Shipping Address Line 1"
            component={TextInput}
            placeholder="Enter Shipping Address Line 1"
          />
          <Field
            name="shippingAddress2"
            type="text"
            label="Shipping Address Line 2"
            component={TextInput}
            placeholder="Enter Shipping Address Line 2"
          />
          <Field
            name="shippingAddress3"
            type="text"
            label="Shipping Address Line 3"
            component={TextInput}
            placeholder="Enter Shipping Address Line 3"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Field
            name="shippingAddress4"
            type="text"
            label="Shipping Address Line 4"
            component={TextInput}
            placeholder="Enter Shipping Address Line 4"
          />
          <Field
            name="shippingAddress5"
            type="text"
            label="Shipping Address Line 5"
            component={TextInput}
            placeholder="Enter Shipping Address Line 5"
          />
          <Field
            name="shipToPostalCode"
            type="text"
            label="Shipping Postal Code"
            component={TextInput}
            placeholder="Enter Shipping Postal Code"
          />
        </Form.Group>

        <Divider />
        <Button
          disabled={invalid || submitting || pristine}
          color="blue"
          type="submit"
          loading={loading}
        >
          {isNew ? "CREATE CLIENT" : "UPDATE CLIENT"}
        </Button>
        <Button.Group floated="right">
          <Button onClick={history.goBack} type="button">
            GO BACK
          </Button>
        </Button.Group>
      </Form>
    </Segment>
  );
};

export default compose(
  withRouter,
  connect(mapState),
  reduxForm({ form: "clientsForm", enableReinitialize: true, validate })
)(ClientsForm);
