import React from "react";
import { Divider, Table, Label, Segment } from "semantic-ui-react";
import { useStats } from "../../../app/context/stats";

const UserStatsDrawer = () => {
  const stats = useStats();

  return (
    <Segment basic loading={!stats.users} style={{ padding: 0 }}>
      <div className="widget users-table">
        <h2>
          Web Users{" "}
          <Label className="pull-right">TOTAL: {stats.users?.all}</Label>
        </h2>
        <Divider />
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.Cell className="mobile-label client-users">
                {stats.users?.isClientUser}
              </Table.Cell>
              <Table.Cell className="mobile-label client-admin">
                {stats.users?.isClientAdmin}
              </Table.Cell>
              <Table.Cell className="mobile-label super-admin">
                {stats.users?.isSuperAdmin}
              </Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Client Users</Table.Cell>
              <Table.Cell>Client Admin</Table.Cell>
              <Table.Cell>Super Admin</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    </Segment>
  );
};
export default UserStatsDrawer;
