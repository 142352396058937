import React from "react";
import Sidebar from "./ui/sidebar/Sidebar";
import { Route, Switch } from "react-router-dom";
import LoginPage from "../../features/login/LoginPage";
import Users from "../../features/users";
import EditWebUser from "../../features/users/edit";
import CreateWebUser from "../../features/users/create";
import Activities from "../../features/activities";
import Passes from "../../features/passes";
import EditPass from "../../features/passes/edit";
import CreatePass from "../../features/passes/create";
import Boxes from "../../features/boxes";
import BoxDetails from "../../features/boxes/details";
import EditBox from "../../features/boxes/edit";
import CreateBox from "../../features/boxes/create";
import Clients from "../../features/clients";
import ClientDetails from "../../features/clients/details";
import EditClient from "../../features/clients/edit";
import CreateClient from "../../features/clients/create";
import Dashboard from "../../features/dashboard";
import { useAuth } from "../context/auth";
import LogoutWarning from "../../features/login/LogoutWarning";

export default function Router() {
  const { role, showWarning } = useAuth();

  const adminRoutes = [
    {
      path: "/clients/create",
      component: CreateClient,
    },
    {
      path: "/clients/client/:id",
      component: EditClient,
    },
    {
      path: "/clients/client-details/:id",
      component: ClientDetails,
    },
    {
      path: "/clients",
      component: Clients,
    },
    {
      path: "/users/create",
      component: CreateWebUser,
    },
    {
      path: "/users/user/:id",
      component: EditWebUser,
    },
    {
      path: "/users",
      component: Users,
    },
  ];
  const routes = (routeArr, name) => {
    return routeArr.map((route, idx) => (
      <Route
        key={`${idx}-name`}
        exact
        path={route.path}
        component={route.component}
      />
    ));
  };

  return (
    <div className="reky">
      <Switch>
        <Route exact path="/" component={LoginPage} />
      </Switch>

      <Route
        path="/(.+)"
        render={() => (
          <div className="app-container">
            {showWarning && <LogoutWarning />}
            <Sidebar />
            <div className="page-content">
              <Switch>
                <Route path="/" exact component={LoginPage} />
                {role.isSuperAdmin && routes(adminRoutes, "admin")}
                <Route path="/dashboard" exact component={Dashboard} />
                <Route path="/boxes/create" exact component={CreateBox} />
                <Route
                  path="/boxes/create/:clientId"
                  exact
                  component={CreateBox}
                />
                <Route path="/boxes/box/:id" exact component={EditBox} />
                <Route
                  path="/boxes/box-details/:boxId"
                  exact
                  component={BoxDetails}
                />
                <Route path="/boxes" exact component={Boxes} />
                <Route path="/passes/create" exact component={CreatePass} />
                <Route
                  path="/passes/create/:boxId"
                  exact
                  component={CreatePass}
                />
                <Route path="/passes/pass/:id" exact component={EditPass} />
                <Route path="/passes" exact component={Passes} />
                <Route path="/activities" exact component={Activities} />
              </Switch>
            </div>
          </div>
        )}
      />
    </div>
  );
}
