import isEmpty from 'lodash/isEmpty';
import { createReducer } from '../../app/common/util/reducerUtil';
import { SET_CURRENT_USER } from './authConstants';

const initialState = {
	isAuthenticated: false,
	webuser: {}
};

export const setCurrentUser = (state, payload = {}) => {
	return {
		isAuthenticated: !isEmpty(payload),
		profile: payload
	};
};

export default createReducer(initialState, {
	[SET_CURRENT_USER]: setCurrentUser
});
