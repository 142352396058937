import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import ReduxToaster from "react-redux-toastr";
import "./index.css";
import "semantic-ui-css/semantic.min.css";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import App from "./app/layout/App";
import * as serviceWorker from "./serviceWorker";

///* global BigInt */

import { configureStore } from "./app/store/configureStore";
const store = configureStore();

const rootEl = document.getElementById("root");

function loadGoogleTagManager() {
  const script1 = document.createElement("script");
  script1.async = true;
  script1.src = "https://www.googletagmanager.com/gtag/js?id=G-X8RFHPNW3L";
  document.head.appendChild(script1);

  const script2 = document.createElement("script");
  script2.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-X8RFHPNW3L');
  `;
  document.head.appendChild(script2);
}

loadGoogleTagManager();

let render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <div>
          <ReduxToaster
            position="top-right"
            transitionIn="bounceInDown"
            transitionOut="bounceOutUp"
            progressBar={false}
          />
          <App />
        </div>
      </BrowserRouter>
    </Provider>,
    rootEl
  );
};

if (module.hot) {
  module.hot.accept("./app/layout/App", () => {
    setTimeout(render);
  });
}

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
