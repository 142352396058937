import React from "react";
import { Link } from "react-router-dom";
import { Button, Icon, Popup } from "semantic-ui-react";
import { useBoxes } from "../../../../app/context/boxes";
import { friendlyName } from "../utils";

// ========================
//     Cell Components
//=========================

const ActionsCell = ({ box }) => (
  <>
    <EditBoxPopup id={box?.id} />
    <ViewPassesPopup id={box?.id} />
    <LinkNewPassPopup id={box?.id} />
  </>
);

const BoxNameCell = ({ value }) => (
  <>
    <div className="box-friendly-name">
      {value.friendlyName ? value.friendlyName : friendlyName(value)}
    </div>
    <div className="btmac-label">{value.btMac}</div>
  </>
);
const BoxAddressCell = ({ value }) => {
  return (
    <div className="box-address">{value.locName ? value.locName : "-"}</div>
  );
};

const CheckIcon = ({ title }) => (
  <Icon name="check" color="green" title={`Linked to Client ID: ${title}`} />
);

const CrossIcon = () => (
  <Icon name="unlink" color="red" title="Not Linked to Client" />
);
const IdleIndicator = ({ isActive }) => {
  return isActive || isActive === null ? (
    <Icon name="circle" color="green" />
  ) : (
    <Icon name="circle" color="red" />
  );
};
// ------ Pop ups ------ //

const DeLinkBoxPopup = ({ box }) => {
  const { deLinkBox } = useBoxes();
  return (
    <Popup
      trigger={<Button icon="unlink" onClick={() => deLinkBox(box)} />}
      content="De-Link Box"
    />
  );
};
const EditBoxPopup = ({ id }) => (
  <Popup
    trigger={
      <Button icon="pencil alternate" as={Link} to={`/boxes/box/${id}`} />
    }
    content="Edit Box Details"
  />
);
const EmptyBatteryPopup = () => (
  <Popup
    trigger={<Icon name="battery empty" color="red" />}
    content="Empty Battery"
  />
);
const FullBatteryPopup = () => (
  <Popup
    trigger={<Icon name="battery full" color="green" />}
    content="Full Battery"
  />
);
const IdleSettingPopup = () => (
  <Popup trigger={<Icon name="hourglass half" color="grey" />} content="Idle" />
);
const LinkBoxPopup = ({ id }) => (
  <Popup
    trigger={<Button icon="linkify" as={Link} to={`/boxes/box/${id}`} />}
    content="Link Box"
  />
);
const LinkNewPassPopup = ({ id }) => (
  <Popup
    trigger={<Button icon="linkify" as={Link} to={`/passes/create/${id}`} />}
    content="Link New Pass"
  />
);
const ViewPassesPopup = ({ id }) => (
  <Popup
    trigger={
      <Button icon="address card" as={Link} to={`/boxes/box-details/${id}`} />
    }
    content="View Passes"
  />
);

export {
  ActionsCell,
  BoxNameCell,
  BoxAddressCell,
  CheckIcon,
  CrossIcon,
  DeLinkBoxPopup,
  EditBoxPopup,
  EmptyBatteryPopup,
  FullBatteryPopup,
  IdleSettingPopup,
  LinkBoxPopup,
  LinkNewPassPopup,
  ViewPassesPopup,
  IdleIndicator,
};
