import React from "react";
import { Link } from "react-router-dom";
import { Table, Button, Icon, Popup, Label } from "semantic-ui-react";
import { formatAppToken } from "../../../app/common/util/formatAppToken";
import { useAuth } from "../../../app/context/auth";
import { usePasses } from "../../../app/context/passes";

const PassesListTable = ({ passes }) => {
  const { resetPassExpiry, deletePass } = usePasses();
  const { role } = useAuth();
  const formatFriendlyName = (fn) => {
    const splitIntoLabels = fn.split("||");
    return splitIntoLabels;
  };

  return (
    <Table striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell textAlign="center" width="1">
            Active
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center" width="1">
            Box
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center" width="1">
            App User
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center" width="1">
            Expiry
          </Table.HeaderCell>
          <Table.HeaderCell width="8">Details</Table.HeaderCell>
          <Table.HeaderCell textAlign="center" width="2">
            App Token
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center" width="2">
            Actions
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {passes &&
          passes.map((pass) => (
            <Table.Row key={pass.id}>
              <Table.Cell textAlign="center" className="mobile-label is-active">
                {pass.archived !== true ? (
                  <Icon name="check" color="green" />
                ) : (
                  <Icon name="close" color="red" />
                )}
              </Table.Cell>
              <Table.Cell textAlign="center" className="mobile-label box-link">
                {!pass.tblBoxListId || pass.tblBoxListId === null ? (
                  <Icon name="unlink" color="red" title="Not linked to Box" />
                ) : (
                  <Icon
                    name="linkify"
                    color="grey"
                    title={`Linked to box ID: ${pass.tblBoxListId}`}
                  />
                )}
              </Table.Cell>
              <Table.Cell textAlign="center" className="mobile-label app-user">
                {pass.tblAppUserId && pass.tblAppUserId !== 0 ? (
                  <Icon
                    name="user plus"
                    color="grey"
                    title={`Linked to App User ID: ${pass.tblAppUserId}`}
                  />
                ) : (
                  <Icon
                    name="user times"
                    color="red"
                    title="Not linked to an App User"
                  />
                )}
              </Table.Cell>
              <Table.Cell textAlign="center" className="mobile-label expiry">
                {/* Pass Never Expires */}
                {pass.neverExpire && (
                  <Label title="Never Expires" color="teal">
                    ∞
                  </Label>
                )}
                {/* Pass Already Used */}
                {pass.expired && !pass.neverExpire && (
                  <Label title="Pass Used" color="orange">
                    <strike>1</strike>
                  </Label>
                )}
                {/* Pass is set as 1 time use */}
                {!pass.expired && !pass.neverExpire && (
                  <Label title="1 Time Use" color="olive">
                    1
                  </Label>
                )}
              </Table.Cell>
              <Table.Cell
                textAlign="center"
                className="friendly-name mobile-border-top mobile-border-bottom"
              >
                {formatFriendlyName(pass.friendlyName).map((label) => {
                  const part = label.split("=");
                  return (
                    <div className="label" key={label}>
                      <span className="key">{part[0]}</span>
                      {part[1] && <span className="value">{part[1]}</span>}
                    </div>
                  );
                })}
              </Table.Cell>
              <Table.Cell
                textAlign="center"
                className="friendly-name mobile-border-top mobile-border-bottom"
              >
                {pass.appToken ? formatAppToken(pass.appToken) : "-"}
              </Table.Cell>
              <Table.Cell textAlign="right">
                <Popup
                  trigger={
                    <Button
                      icon="pencil alternate"
                      as={Link}
                      to={`/passes/pass/${pass.id}`}
                    />
                  }
                  content="View Pass Details"
                />
                {/* Pass Already Used */}
                {pass.expired && !pass.neverExpire && (
                  <Popup
                    trigger={
                      <Button
                        icon="repeat"
                        onClick={() => resetPassExpiry(pass)}
                      />
                    }
                    content="Reset Pass"
                  />
                )}
                {/* {pass.tblAppUserId && pass.tblAppUserId !== 0 ? (
										<Popup
											trigger={
												<Button
													icon="user times"
													onClick={() => deLinkAppUser(pass)}
												/>
											}
											content="De-Link Pass AppUser"
										/>
									) : null} */}
                {!role.isClientUser && (
                  <Popup
                    trigger={
                      <Button
                        icon="trash alternate"
                        className="delete"
                        onClick={() => deletePass(pass)}
                      />
                    }
                    content="Delete Pass"
                  />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  );
};
export default PassesListTable;
