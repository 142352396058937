import React from "react";
import Table from "../../../app/common/Table";
import matchSorter from "match-sorter";
import { Icon, Label, Button, Popup, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useUsers } from "../../../app/context/users";

export default () => {
  const { users, deleteUser } = useUsers();

  const columns = [
    {
      Header: "Active",
      id: "status",
      accessor: "status",
      className: "centered",
      Cell: ({ value }) =>
        value === true ? (
          <Icon name="lightning" color="green" />
        ) : (
          <Icon name="archive" color="grey" />
        ),
      filterable: false,
      width: 60,
    },
    // ================================
    {
      Header: "Role",
      accessor: "tblACLID",
      className: "centered",
      id: "tblACLID",
      Cell: ({ value }) => <Label>{getRoleLabel(value)}</Label>,
      filterable: false,
      width: 120,
    },
    // ================================
    {
      Header: "Client",
      accessor: "client.clientName",
      id: "clientName",
      Cell: ({ value }) => (value ? value : " - "),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: ["clientName"],
        }),
      filterAll: true,
      minWidth: 70,
    },
    // ================================
    {
      Header: "Name",
      id: "name",
      accessor: (original) => `${original.firstName} ${original.lastName}`,
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: ["name"],
        }),

      filterAll: true,
      minWidth: 70,
    },
    // ================================

    {
      Header: "Email",
      accessor: "email",
      id: "email",
      Cell: ({ value }) => (value ? value : " - "),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: ["email"],
        }),
      filterAll: true,
      minWidth: 80,
    },
    // ================================
    {
      Header: "Mobile",
      accessor: "mobile",
      id: "mobile",
      Cell: ({ value }) => (value ? value : " - "),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: ["mobile"],
        }),
      filterAll: true,
      width: 150,
    },
    // ================================
    {
      Header: "Actions",
      id: "actions",
      Cell: ({ original }) => (
        <>
          <Popup
            trigger={
              <Button
                icon="pencil alternate"
                as={Link}
                to={`/users/user/${original.id}`}
              />
            }
            content="Edit User Details"
          />
          <Popup
            trigger={
              <Button
                icon="trash alternate"
                onClick={() => deleteUser(original)}
                className="delete"
              />
            }
            content="Delete User"
          />
        </>
      ),
      className: "no-wrap right",
      filterable: false,
      width: 100,
    },
    // ================================
  ];
  return (
    <Segment loading={!users} basic>
      <Table columns={columns} data={users} defaultPageSize={20} />
    </Segment>
  );
};

function getRoleLabel(id) {
  let label = "role";
  switch (id) {
    case 1:
      label = "Super User";
      break;
    case 2:
      label = "Client Admin";
      break;
    case 3:
      label = "User";
      break;
    default:
      label = "Unknown";
  }
  return label;
}
