import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import { useBoxes } from "../../../app/context/boxes";
import PageHeader from "../../../app/layout/ui/header/PageHeader";
import SubHeader from "../../../app/layout/ui/header/SubHeader";
import BoxForm from "../form";

const EditBox = () => {
  const params = useParams();
  const { boxes } = useBoxes();
  const box = useMemo(() => {
    console.log(params);
    if (boxes) return boxes?.find((box) => box.id === parseInt(params.id));
  }, [boxes, params]);
  return (
    <div>
      <PageHeader title="Edit Box" />
      <SubHeader title="Edit an existing ReKy Box" />

      <div className="page-wrapper">
        <Segment loading={!box} basic>
          <BoxForm isNew={false} box={box} />
        </Segment>
      </div>
    </div>
  );
};
export default EditBox;
