export const formatUserData = (user, pass) => {
  let userFieldsTemplate = {
    email: "",
    firstName: "",
    lastName: "",
    login: "",
    mobile: "",
    pwdExpires: null,
    tblACLID: "1",
    tblClientProfileID: 0,
  };
  let userFields = {
    ...userFieldsTemplate,
    ...user,
    login: user.login ? user.login : user.email,
  };

  // Handle password change
  delete userFields.password;
  if (pass) {
    userFields.password = pass;
  }

  return {
    ...userFields,
  };
};

export const formatUsers = (users) => {
  return users.reduce((arr, user) => {
    return (arr = [...arr, { ...user.tblwebuser, ...user }]);
  }, []);
};

export const formatUserForList = (user) => {
  return { ...user, ...user.tblwebuser };
};
