import React from "react";
import styled from "styled-components";
import { useAuth } from "../../../../app/context/auth";
import LoaderBounceDot from "../../../../app/layout/ui/LoaderBouncingDot";

const StatsLoaderRow = styled.div`
  background: #fff;
  padding: 30px;
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  grid-gap: 30px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
const StatsLoaderBlock = styled.div`
  height: 122px;
  background: #3c415e;
  border: 1px solid #eee;
  border-radius: 5px;
  text-align: center;
`;

const StockStatsDrawerLoader = () => {
  const { role } = useAuth();

  return (
    <StatsLoaderRow columns={role.isSuperAdmin ? 3 : 2}>
      {role.isSuperAdmin ? (
        <StatsLoaderBlock>
          <LoaderBounceDot />
        </StatsLoaderBlock>
      ) : null}
      <StatsLoaderBlock>
        <LoaderBounceDot />
      </StatsLoaderBlock>
      <StatsLoaderBlock>
        <LoaderBounceDot />
      </StatsLoaderBlock>
    </StatsLoaderRow>
  );
};

export default StockStatsDrawerLoader;
