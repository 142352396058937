import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  reduxForm,
  Field,
  formValueSelector,
  SubmissionError,
} from "redux-form";
import { useHistory, withRouter } from "react-router-dom";
import {
  Segment,
  Form,
  Button,
  Header,
  Divider,
  Grid,
} from "semantic-ui-react";
// import {
//   composeValidators,
//   combineValidators,
//   isRequired,
//   createValidator,
// } from "revalidate";
import BoxSelectInput from "../../../app/common/form/BoxSelectInput";
import MaskedTextInput from "../../../app/common/form/MaskedTextInput";
import CheckboxSwitch from "../../../app/common/form/CheckboxSwitch";
import DropdownInput from "../../../app/common/form/DropDownInput";
import DateSelector from "../../../app/common/form/DateSelector";
import { formatAppToken } from "../../../app/common/util/formatAppToken";
import { usePasses } from "../../../app/context/passes";
import { useAsync } from "../../../app/context/async";
import {
  combineValidators,
  composeValidators,
  createValidator,
  isRequired,
} from "revalidate";
const selector = formValueSelector("passesForm");
const mapState = (state, ownProps) => {
  const activeBox = ownProps.box;
  const pass = ownProps.pass ? ownProps.pass : {};
  // check if we're coming from a box profile to create a new pass for specific box
  const isBoxSpecificNewPass = ownProps.box && ownProps.isNew ? true : false;

  // default times
  // start
  const defaultStartTime = new Date();
  defaultStartTime.setHours(0, 0, 0, 0);

  // end
  const defaultEndTime = new Date();
  defaultEndTime.setHours(23, 59, 0, 0);

  // Existing Pass
  if (ownProps.isNew) {
    // set default values in case these fields are never touched
    pass.neverExpire = true;
    pass.timeFrom = defaultStartTime;
    pass.timeTo = defaultEndTime;
    pass.accessRelayNo1 = true;
    pass.accessRelayNo2 = true;
    pass.useOncePerWindow = false;
  }

  if (ownProps.box) {
    pass.tblBoxListId = ownProps.box.id;
  }

  const neverExpire = selector(state, "neverExpire");

  return {
    pass,
    activeBox,
    defaultStartTime,
    defaultEndTime,
    isBoxSpecificNewPass,
    initialValues: pass,
    loading: state.async.loading,
    vals: { neverExpire },
  };
};

const expireOptions = [
  { key: true, text: "Never Expire", value: true },
  { key: false, text: "Expire", value: false },
];

const isValidAppToken = createValidator(
  (message) => (value) => {
    if (
      value &&
      !/^([0-9][0-9][0-9][0-9]-){3}([0-9][0-9][0-9][0-9])$/i.test(value)
    ) {
      return message;
    }
  },
  "Invalid APP Token"
);

const validateNew = combineValidators({
  appToken: composeValidators(
    isRequired({
      message:
        "A valid APP Token containing digital only is required in the format 0000-0000-0000-0000",
    }),
    isValidAppToken
  )(),
});

const PassForm = ({
  pass,
  isNew,
  invalid,
  submitting,
  deletePass,
  resetPassExpiry,
  pristine,
  activeBox,
  defaultEndTime,
  defaultStartTime,
  isBoxSpecificNewPass,
  handleSubmit,
  vals,
}) => {
  const { updatePass, createPass } = usePasses();

  const onFormSubmit = async (values) => {
    const errors = validateNew(values);
    if (Object.keys(errors).length) {
      throw new SubmissionError(errors);
    }

    if (!isNew) {
      updatePass(values);
    } else {
      await createPass(values);
      history.push("/passes");
    }
  };
  const { loading } = useAsync();
  const history = useHistory();

  useEffect(() => console.log(vals), [vals]);

  return (
    <Segment loading={loading}>
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        {isNew && !isBoxSpecificNewPass && (
          <Form.Group widths="equal">
            <Field name="tblBoxListId" label="Box" component={BoxSelectInput} />
          </Form.Group>
        )}
        <Grid columns="equal" className="static-content">
          <Grid.Row>
            {activeBox && (
              <Grid.Column>
                <div className="box">
                  <p>BOX:</p>
                  <p>
                    <strong>
                      {activeBox.friendlyName ? activeBox.friendlyName : "-"}
                    </strong>
                  </p>
                </div>
              </Grid.Column>
            )}
            {activeBox && (
              <Grid.Column>
                <div className="box">
                  <p>SERIAL:</p>
                  <p>
                    <strong>
                      {activeBox.secondaryGuid ? activeBox.secondaryGuid : "-"}
                    </strong>
                  </p>
                </div>
              </Grid.Column>
            )}
            {!isNew && (
              <Grid.Column>
                <div className="box">
                  <p>CLIENT ID:</p>
                  <p>
                    {pass && (
                      <strong>
                        {pass.tblClientProfileId && pass.tblClientProfileId}
                      </strong>
                    )}
                  </p>
                </div>
              </Grid.Column>
            )}
            {!isNew && (
              <Grid.Column>
                <div className="box">
                  <p>APP USER ID:</p>
                  <p>
                    {pass && (
                      <strong>{pass.tblAppUserId && pass.tblAppUserId}</strong>
                    )}
                  </p>
                </div>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
        <Header sub color="blue" content="Pass Details" />
        <Divider />
        <Form.Group widths="equal">
          {pass.appToken ? (
            <div className="static-form-field">
              <div className="label">App Token</div>
              <div className="value">{formatAppToken(pass.appToken)}</div>
            </div>
          ) : (
            <Field
              name="appToken"
              label="APP Token"
              component={MaskedTextInput}
              placeholder="____-____-____-____"
              mask="1111-1111-1111-1111"
              size="19"
              disabled={!isNew}
            />
          )}
        </Form.Group>
        <Header sub color="blue" content="Expiry" />
        <Divider />
        <Form.Group widths="equal">
          <Field
            component={DateSelector}
            name="timeFrom"
            label="Time From"
            showTimeSelect={true}
            showTimeSelectOnly={true}
            timeIntervals={1}
            dateFormat="HH:mm"
            timeFormat="HH:mm"
          />
          <Field
            component={DateSelector}
            name="timeTo"
            label="Time To"
            showTimeSelect={true}
            showTimeSelectOnly={true}
            timeIntervals={1}
            dateFormat="HH:mm"
            timeFormat="HH:mm"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Field
            name="neverExpire"
            type="text"
            label="The pass should:"
            component={DropdownInput}
            options={expireOptions}
            defaultValue={true}
          />
        </Form.Group>
        {vals?.neverExpire === false && (
          <Form.Group widths="equal">
            <Field
              component={DateSelector}
              name="dateFrom"
              label="Date From"
              showTimeSelect={false}
              showTimeSelectOnly={false}
              dateFormat="d MMMM yyyy"
            />
            <Field
              component={DateSelector}
              name="dateTo"
              label="Date To"
              showTimeSelect={false}
              showTimeSelectOnly={false}
              dateFormat="d MMMM yyyy"
            />
            <Field
              component={CheckboxSwitch}
              name="useOncePerWindow"
              label="Use once per window?"
              defaultChecked={pass.useOncePerWindow}
            />
            {!pass.expired ? (
              <Button
                type="button"
                onClick={() => {
                  resetPassExpiry(pass);
                }}
              >
                Reset Expired
              </Button>
            ) : null}
          </Form.Group>
        )}

        <Header sub color="blue" content="Relay Details" />
        <Divider />
        {pass.accessRelayNo1 !== undefined &&
          pass.accessRelayNo2 !== undefined && (
            <Form.Group widths="equal">
              <Field
                component={CheckboxSwitch}
                name="accessRelayNo1"
                label={
                  activeBox?.relayName1
                    ? `Relay 1: ${activeBox.relayName1}`
                    : "Relay 1"
                }
                align="toggle-center"
                defaultChecked={pass && pass.accessRelayNo1}
              />
              <Field
                component={CheckboxSwitch}
                name="accessRelayNo2"
                label={
                  activeBox?.relayName2
                    ? `Relay 2: ${activeBox.relayName2}`
                    : "Relay 2"
                }
                align="toggle-center"
                defaultChecked={pass && pass.accessRelayNo2}
              />
            </Form.Group>
          )}
        <Divider />
        <Button
          // disabled={invalid || submitting || pristine}
          color="blue"
          type="submit"
          loading={loading}
        >
          {isNew ? "CREATE PASS" : "UPDATE PASS"}
        </Button>
        {isNew ? null : (
          <Button
            type="button"
            onClick={() => {
              deletePass(pass);
            }}
            color="red"
            loading={loading}
          >
            DELETE
          </Button>
        )}
        <Button.Group floated="right">
          <Button onClick={history.goBack} type="button">
            GO BACK
          </Button>
        </Button.Group>
      </Form>
    </Segment>
  );
};

export default compose(
  connect(mapState),
  reduxForm({
    form: "passesForm",
    enableReinitialize: true,
    validate: validateNew,
  })
)(PassForm);
