import React from "react";
import PageHeader from "../../app/layout/ui/header/PageHeader";
import SubHeader from "../../app/layout/ui/header/SubHeader";
import StockStatsDrawer from "./StockStatsDrawer";
import UserStatsDrawer from "./UserStatsDrawer";
import DashboardWelcomeWidget from "./DashboardWelcomeWidget";
import { useAuth } from "../../app/context/auth";

const Dashboard = () => {
  const { role } = useAuth();
  return (
    <div>
      <PageHeader title="Dashboard" icon="tachometer" />
      <SubHeader title="Welcome to ReKy" />
      <StockStatsDrawer />
      <div className="page-padding">
        <DashboardWelcomeWidget />
        {role.isSuperAdmin && <UserStatsDrawer />}
      </div>
    </div>
  );
};
export default Dashboard;
