import React from "react";
import { ActivitiesProvider } from "../activities";
import { BoxesProvider } from "../boxes";
import { ClientsProvider } from "../clients";
import { PassesProvider } from "../passes";
import { StatsProvider } from "../stats";
import { UsersProvider } from "../users";

export const DataProvider = ({ children }) => {
  return (
    <ActivitiesProvider>
      <BoxesProvider>
        <ClientsProvider>
          <PassesProvider>
            <UsersProvider>
              <StatsProvider>{children}</StatsProvider>
            </UsersProvider>
          </PassesProvider>
        </ClientsProvider>
      </BoxesProvider>
    </ActivitiesProvider>
  );
};
