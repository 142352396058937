import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as icons from "../../ui/icons/";
import { useAuth } from "../../../context/auth";

const PageHeader = ({ title, icon }) => {
  const { user, logout } = useAuth();
  return (
    <header className="page">
      <h1>
        {icon && <FontAwesomeIcon icon={["fal", icon]} />} {title}
      </h1>
      <div className="user">
        <span className="name">Welcome {user?.firstName}</span>
        <button title="Logout" onClick={logout}>
          <FontAwesomeIcon icon={icons.faLockAlt} />
        </button>
      </div>
    </header>
  );
};
export default PageHeader;
