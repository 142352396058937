import React, { useState, useEffect, useMemo } from "react";
import Table from "../../../app/common/Table";
import {
  ActionsCell,
  BoxNameCell,
  CheckIcon,
  CrossIcon,
  BoxAddressCell,
  IdleIndicator,
} from "./cell-components/";
import matchSorter from "match-sorter";
import { hasTblClientProfile } from "./utils";
import { useBoxes } from "../../../app/context/boxes";
import { useClients } from "../../../app/context/clients";
import { Segment } from "semantic-ui-react";

const BoxesTable = () => {
  // Detect Mobile
  const [isMobile, setMobile] = useState(false);
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    updateDimensions();
  }, []);
  const updateDimensions = () => {
    if (window.innerWidth < 1024) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };
  const { boxes } = useBoxes();
  const { clients } = useClients();

  var boxesWithClients = useMemo(() => {
    if (boxes && clients) {
      var clientObj = clients.reduce((a, c) => {
        var id = c.tblClientProfile.id;
        var name = c.tblClientProfile.clientName;
        return { ...a, [id]: name };
      }, {});
      return boxes.map((box) => ({
        ...box,
        clientName: clientObj[box.tblclientprofile],
      }));
    } else {
      return [];
    }
  }, [clients, boxes]);

  const columns = [
    {
      Header: "Linked",
      id: "clientLink",
      accessor: (box) => box,
      Cell: ({ value }) =>
        hasTblClientProfile(value) ? (
          <CheckIcon title={value.tblclientprofile} />
        ) : (
          <CrossIcon />
        ),
      filterable: false,
      className: "centered",
      width: 60,
    },
    {
      Header: "Status",
      id: "idleSetting",
      accessor: "idleSetting",
      Cell: ({ value }) => (
        <IdleIndicator isActive={value === true || value === null} />
      ),
      filterable: false,
      className: "centered",
      width: 60,
      show: !isMobile,
    },
    {
      Header: "Passes",
      accessor: "accessPassCount",
      className: "centered",
      width: 60,
    },
    {
      Header: "Box Name",
      id: "boxName",
      accessor: (box) => box,
      Cell: ({ value }) => <BoxNameCell value={value} />,
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [
            "boxName.friendlyName",
            "boxName.btMac",
            "boxName.locName",
            "boxName.btMac",
          ],
        }),
      filterAll: true,
    },
    {
      Header: "Client Name",
      id: "clientName",
      accessor: (box) => box.clientName,
      filterable: true,
      filterAll: true,
      filterMethod: (filter, rows) => {
        return rows.filter(
          ({ clientName }) =>
            clientName &&
            clientName.toLowerCase().includes(filter.value?.toLowerCase())
        );
      },
      className: "centered",
      //   width: 150,
    },
    {
      Header: "Box #",
      id: "boxId",
      accessor: (box) => box.id,
      Cell: ({ value }) => value,
      filterable: false,
      className: "centered",
      width: 50,
    },
    {
      Header: "Location",
      id: "boxLocation",
      className: "centered",
      accessor: (box) => box,
      Cell: ({ value }) => <BoxAddressCell value={value} />,
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [
            "boxLocation.locName",
            "boxLocation.address1",
            "boxLocation.address2",
            "boxLocation.address3",
            "boxLocation.address4",
          ],
        }),
      filterAll: true,
      maxWidth: 200,
    },
    {
      Header: "Actions",
      id: "actions",
      accessor: (box) => box,
      Cell: ({ value }) => <ActionsCell box={value} />,
      filterable: false,
      className: "right",
      width: 140,
    },
  ];

  return (
    <Segment basic loading={!boxes}>
      <Table columns={columns} data={boxesWithClients} defaultPageSize={20} />
    </Segment>
  );
};
export default BoxesTable;
