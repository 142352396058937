import React from "react";
import Table from "../../../app/common/Table";
import { Label, Segment } from "semantic-ui-react";
import matchSorter from "match-sorter";
import format from "date-fns/format";

const formatFriendlyName = (fn) => {
  const splitIntoLabels = fn.split("||");
  return splitIntoLabels;
};

const formatDate = (utcDate) => {
  const localDate = new Date(utcDate);
  return format(localDate, "yyyy/MM/dd @ HH:mm:ss");
};

export default ({ activities, numberOfRecords }) => {
  const columns = [
    {
      Header: "Timestamp",
      id: "timeStamp",
      accessor: "activity.timeStamp",
      width: 200,
      Cell: ({ value }) => (value ? formatDate(value) : "-"),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: ["timeStamp"],
        }),
      filterAll: true,
    },
    {
      Header: "Activity",
      accessor: "activity.activity",
      id: "activity",
      Cell: ({ value }) => (value ? value : " - "),
      filterable: false,
      minWidth: 40,
    },
    {
      Header: "App User",
      accessor: "appUser.friendlyName",
      id: "friendlyNameApp",
      Cell: ({ value }) => (value ? value : " - "),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: ["friendlyNameApp"],
        }),
      filterAll: true,
      minWidth: 50,
    },
    {
      Header: "Device Name",
      accessor: "appUser.deviceName",
      id: "deviceName",
      Cell: ({ value }) => (value ? value : " - "),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: ["deviceName"],
        }),
      filterAll: true,
      minWidth: 70,
    },
    {
      Header: "Box Name",
      accessor: "rekyBox.friendlyName",
      id: "friendlyNameBox",
      Cell: ({ value }) => (value ? value : " - "),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: ["friendlyNameBox"],
        }),
      filterAll: true,
      minWidth: 60,
    },
    {
      Header: "Box Location",
      accessor: "rekyBox.locName",
      id: "locName",
      Cell: ({ value }) => (value ? value : " - "),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: ["locName"],
        }),
      filterAll: true,
      minWidth: 50,
    },
    {
      Header: "Access Pass",
      id: "accessPass",
      accessor: "accessPass",
      Cell: ({ value }) =>
        value
          ? formatFriendlyName(value.friendlyName).map(
              (label, i) =>
                label !== "" && (
                  <Label key={i} className="label-in-table">
                    {label}
                  </Label>
                )
            )
          : null,
      className: "no-wrap right",
      filterable: false,
    },
  ];

  return (
    <Segment loading={!activities} basic>
      <Table
        numberOfRecords={numberOfRecords}
        columns={columns}
        data={activities ? activities : []}
        defaultPageSize={20}
        defaultSorted={[
          {
            id: "timeStamp",
            desc: true,
          },
        ]}
      />
    </Segment>
  );
};
