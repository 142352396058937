import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faEdit } from "../../ui/icons/";
import { useAuth } from "../../../context/auth";

const SubHeader = ({
  title,
  actionTo,
  actionTxt,
  search,
  handleSearch,
  actionType,
  withCreatePass,
  boxID,
}) => {
  const { role } = useAuth();

  return (
    <div className="sub-header">
      <h2>{title}</h2>
      <div className="search-box">
        {search && (
          <input type="text" placeholder="Search..." onKeyUp={handleSearch} />
        )}
      </div>
      {!role.isClientUser && actionTo && actionTxt ? (
        <div className="actions">
          {withCreatePass && (
            <Link to={"/passes/create/" + boxID}>
              <FontAwesomeIcon icon={faPlusCircle} />
              Create Pass
            </Link>
          )}
          <Link to={actionTo}>
            {actionType === "create" && <FontAwesomeIcon icon={faPlusCircle} />}
            {actionType === "edit" && <FontAwesomeIcon icon={faEdit} />}
            {actionTxt}
          </Link>
        </div>
      ) : null}
    </div>
  );
};
export default SubHeader;
