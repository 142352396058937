import React from "react";
import Table from "../../../app/common/Table";
import { Icon, Label, Segment } from "semantic-ui-react";
import matchSorter from "match-sorter";
import { Link } from "react-router-dom";
import { Button, Popup } from "semantic-ui-react";
import { formatAppToken } from "../../../app/common/util/formatAppToken";
import { usePasses } from "../../../app/context/passes";
import { useAuth } from "../../../app/context/auth";

function formatFriendlyName(fn) {
  const splitIntoLabels = fn.split("|");
  return splitIntoLabels;
}

const ExpiryCell = ({ pass }) => {
  return (
    <>
      {pass.neverExpire && (
        <Label title="Never Expires" color="teal">
          ∞
        </Label>
      )}
      {/* Pass Already Used */}
      {pass.expired && !pass.neverExpire && (
        <Label title="Pass Used" color="orange">
          <strike>1</strike>
        </Label>
      )}
      {/* Pass is set as 1 time use */}
      {!pass.expired && !pass.neverExpire && (
        <Label title="1 Time Use" color="olive">
          1
        </Label>
      )}
    </>
  );
};

const FriendlyNameCell = ({ friendlyName }) => {
  return formatFriendlyName(friendlyName).map((label, i) => {
    const parts = label.split("=");
    return (
      parts.length === 2 && (
        <div className="label" key={i}>
          <span className="key">{parts[0]}</span>
          {parts[1] && <span className="value">{parts[1]}</span>}
        </div>
      )
    );
  });
};

const ActionCell = ({ pass }) => {
  const { resetPassExpiry, deLinkAppUser, deletePass } = usePasses();
  const { role } = useAuth();
  return (
    <>
      <Popup
        trigger={
          <Button
            icon="pencil alternate"
            as={Link}
            to={`/passes/pass/${pass.id}`}
          />
        }
        content="View Pass Details"
      />

      {pass.expired && !pass.neverExpire ? (
        <Popup
          trigger={
            <Button icon="repeat" onClick={() => resetPassExpiry(pass)} />
          }
          content="Reset Pass"
        />
      ) : null}

      {pass.tblAppUserId && pass.tblAppUserId !== 0 ? (
        <Popup
          trigger={
            <Button icon="user times" onClick={() => deLinkAppUser(pass)} />
          }
          content="De-Link Pass AppUser"
        />
      ) : null}

      {!role.isClientUser && (
        <Popup
          trigger={
            <Button
              icon="trash alternate"
              className="delete"
              onClick={() => deletePass(pass)}
            />
          }
          content="Delete Pass"
        />
      )}
    </>
  );
};
const PassesTable = () => {
  const { passes } = usePasses();

  const columns = [
    {
      Header: "Active",
      id: "archived",
      accessor: "archived",
      Cell: ({ value }) =>
        value !== true ? (
          <Icon name="check" color="green" />
        ) : (
          <Icon name="close" color="red" />
        ),
      filterable: false,
      className: "centered",
      width: 60,
    },
    {
      Header: "Box",
      accessor: "tblBoxListId",
      Cell: ({ value }) =>
        !value || value === null ? (
          <Icon name="unlink" color="red" title="Not linked to Box" />
        ) : (
          <Icon
            name="linkify"
            color="grey"
            title={`Linked to box ID: ${value}`}
          />
        ),
      className: "centered",
      filterable: false,
      width: 40,
    },
    {
      Header: "App User",
      accessor: "tblAppUserId",
      Cell: ({ value }) =>
        value && value !== 0 ? (
          <Icon
            name="user plus"
            color="grey"
            title={`Linked to App User ID: ${value}`}
          />
        ) : (
          <Icon
            name="user times"
            color="red"
            title="Not linked to an App User"
          />
        ),
      className: "centered",
      filterable: false,
      width: 80,
    },
    {
      Header: "Expiry",
      id: "expiry",
      Cell: ({ original }) => <ExpiryCell pass={original} />,
      className: "centered",
      filterable: false,
      width: 60,
    },
    {
      Header: "Details",
      id: "friendlyName",
      accessor: "friendlyName",
      Cell: ({ value }) => <FriendlyNameCell friendlyName={value} />,
      className: "friendly-name no-wrap",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: ["friendlyName"],
        }),
      filterAll: true,
    },
    {
      Header: "App Token",
      id: "appToken",
      accessor: "appToken",
      width: 160,
      className: "table-center",
      Cell: ({ value }) => (value ? formatAppToken(value) : "-"),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: ["appToken"],
        }),
      filterAll: true,
    },
    {
      Header: "Actions",
      id: "actions",
      Cell: ({ original }) => {
        return <ActionCell pass={original} />;
      },
      filterable: false,
      width: 140,
      className: "right",
    },
  ];

  return (
    <Segment loading={!passes} basic>
      <Table columns={columns} data={passes} defaultPageSize={20} />
    </Segment>
  );
};
export default PassesTable;
