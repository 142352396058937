import React, { createContext, useContext, useMemo } from "react";
import { useBoxes } from "../boxes";
import { useClients } from "../clients";
import { usePasses } from "../passes";
import { useUsers } from "../users";

const StatsContext = createContext({});
export const StatsProvider = ({ children }) => {
  const { clients } = useClients();
  const clientStats = useMemo(
    () => (clients ? { all: clients.length } : null),
    [clients]
  );
  const { boxes } = useBoxes();
  const boxStats = useMemo(() => (boxes ? { all: boxes.length } : null), [
    boxes,
  ]);
  const { passes } = usePasses();
  const passStats = useMemo(() => (passes ? { all: passes.length } : null), [
    passes,
  ]);
  const { users } = useUsers();
  const userStats = useMemo(() => {
    if (users) {
      const superAdminCount = users.filter(
        (user) => user.tblwebuser.tblACLID === 1
      ).length;

      const clientAdminCount = users.filter(
        (user) => user.tblwebuser.tblACLID === 2
      ).length;

      const clientUserCount = users.filter(
        (user) => user.tblwebuser.tblACLID === 3
      ).length;
      return {
        all: users.length,
        isSuperAdmin: superAdminCount,
        isClientAdmin: clientAdminCount,
        isClientUser: clientUserCount,
      };
    }
  }, [users]);
  return (
    <StatsContext.Provider
      value={{
        clients: clientStats,
        boxes: boxStats,
        passes: passStats,
        users: userStats,
      }}
    >
      {children}
    </StatsContext.Provider>
  );
};
export const StatsConsumer = StatsContext.Consumer;
export const useStats = () => useContext(StatsContext);
