import React from "react";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import { useClients } from "../../../app/context/clients";
import PageHeader from "../../../app/layout/ui/header/PageHeader";
import SubHeader from "../../../app/layout/ui/header/SubHeader";
import ClientForm from "../form";

const EditClient = () => {
  const { clients } = useClients();
  const params = useParams();
  const client = useMemo(() => {
    console.log(params);
    if (clients)
      return clients?.find((client) => client.id === parseInt(params.id));
  }, [clients, params]);
  return (
    <div>
      <PageHeader title="Edit Client" />
      <SubHeader title="Edit an existing ReKy client" />

      <div className="page-wrapper">
        <Segment loading={!client} basic>
          <ClientForm isNew={false} client={client} />
        </Segment>
      </div>
    </div>
  );
};
export default EditClient;
