import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useUsers } from "../../../app/context/users";
import PageHeader from "../../../app/layout/ui/header/PageHeader";
import SubHeader from "../../../app/layout/ui/header/SubHeader";
import WebUserForm from "../form";

const EditWebUser = () => {
  const { users } = useUsers();
  const params = useParams();
  const user = useMemo(() => {
    if (users) return users.find((u) => u.id === parseInt(params.id));
  }, [params, users]);
  return (
    <div>
      <PageHeader title="Edit Web User" />
      <SubHeader title="Edit an existing web user" />

      <div className="page-wrapper">
        <WebUserForm isNew={false} user={user} />
      </div>
    </div>
  );
};
export default EditWebUser;
