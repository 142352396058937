import React from "react";
import { Table, Button, Icon, Popup } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { IdleIndicator } from "../../boxes/BoxesTable/cell-components";
import { useBoxes } from "../../../app/context/boxes";

const BoxesListTable = ({ boxes }) => {
  const { deLinkBox } = useBoxes();
  return (
    <Table striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell textAlign="center" width="1">
            Status
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center" width="2">
            Linked to Client
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center" width="2">
            Pass Count
          </Table.HeaderCell>
          <Table.HeaderCell width="7">Box Name</Table.HeaderCell>
          <Table.HeaderCell textAlign="center" width="5">
            Actions
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {boxes &&
          boxes.map((box) => (
            <Table.Row key={box.id}>
              <Table.Cell textAlign="center" className="mobile-label idle">
                <IdleIndicator isActive={box.idleSetting} />
              </Table.Cell>
              <Table.Cell
                textAlign="center"
                className="mobile-label client-link"
              >
                {box.tblclientprofile && box.tblclientprofile !== null ? (
                  <Icon
                    name="check"
                    color="green"
                    title={`Linked to Client ID: ${box.tblclientprofile}`}
                  />
                ) : (
                  <Icon
                    name="unlink"
                    color="red"
                    title="Not Linked to Client"
                  />
                )}
              </Table.Cell>
              <Table.Cell
                textAlign="center"
                className="mobile-label pass-count"
              >
                {box.accessPassCount}
              </Table.Cell>
              <Table.Cell className="mobile-label box">
                <div className="box-friendly-name">
                  {box.friendlyName
                    ? box.friendlyName
                    : box.tblclientprofile +
                      "_" +
                      box.locName +
                      "_" +
                      box.btMac}
                </div>
                <div className="btmac-label">{box.btMac}</div>
              </Table.Cell>
              <Table.Cell textAlign="right" className="mobile-border-top pt20">
                <Popup
                  trigger={
                    <Button
                      icon="pencil alternate"
                      as={Link}
                      to={`/boxes/box/${box.id}`}
                    />
                  }
                  content="Edit Box Details"
                />
                <Popup
                  trigger={
                    <Button
                      icon="address card"
                      as={Link}
                      to={`/boxes/box-details/${box.id}`}
                    />
                  }
                  content="View Passes"
                />
                <Popup
                  trigger={
                    <Button
                      icon="linkify"
                      as={Link}
                      to={`/passes/create/${box.id}`}
                    />
                  }
                  content="Link New Pass"
                />
                {box.tblclientprofile && box.tblclientprofile !== null ? (
                  <Popup
                    trigger={
                      <Button icon="unlink" onClick={() => deLinkBox(box)} />
                    }
                    content="De-Link Box"
                  />
                ) : (
                  <Popup
                    trigger={
                      <Button
                        icon="linkify"
                        as={Link}
                        to={`/boxes/box/${box.id}`}
                      />
                    }
                    content="Link Box"
                  />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  );
};
export default BoxesListTable;
