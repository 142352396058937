import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class NavItem extends Component {
	render() {
		const { title, to, icon, toggle } = this.props;
		return (
			<NavLink to={to} onClick={toggle}>
				<FontAwesomeIcon icon={icon} />
				{title}
			</NavLink>
		);
	}
}
export default NavItem;
