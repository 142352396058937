import React, { Component } from 'react';
import PageHeader from '../../../app/layout/ui/header/PageHeader';
import SubHeader from '../../../app/layout/ui/header/SubHeader';
import BoxForm from '../form';

class CreateBox extends Component {
	render() {
		return (
			<div>
				<PageHeader title="New Box" />
				<SubHeader title="Create a new ReKy Box" />

				<div className="page-wrapper">
					<BoxForm isNew />
				</div>
			</div>
		);
	}
}
export default CreateBox;
