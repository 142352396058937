import React from "react";
import PageHeader from "../../app/layout/ui/header/PageHeader";
import SubHeader from "../../app/layout/ui/header/SubHeader";
import UsersTable from "./UsersTable";

const Users = () => {
  return (
    <div>
      <PageHeader title="Users" />
      <SubHeader
        title="List of all Users"
        actionTo="users/create"
        actionTxt="Create User"
        actionType="create"
      />

      <div className="page-wrapper">
        <UsersTable />
      </div>
    </div>
  );
};
export default Users;
