import { createReducer } from '../../app/common/util/reducerUtil';
import { FETCH_ALL_USERS } from './usersConstants';

const initialState = {};

export const fetchAllUsersData = (state, payload = {}) => {
	return payload;
};

export default createReducer(initialState, {
	[FETCH_ALL_USERS]: fetchAllUsersData
});
