import { createReducer } from '../../app/common/util/reducerUtil';
import {
	DB_FETCH_CLIENTS_STATS,
	DB_FETCH_BOXES_STATS,
	DB_FETCH_PASSES_STATS,
	DB_FETCH_USERS_STATS
} from './dashboardConstants';

const initialState = {};

export const fetchDashboardClientStats = (state, payload = {}) => {
	return { ...state, clients: payload.clientStats };
};

export const fetchDashboardBoxStats = (state, payload = {}) => {
	return { ...state, boxes: payload.boxStats };
};

export const fetchDashboardPassStats = (state, payload = {}) => {
	return { ...state, passes: payload.passStats };
};

export const fetchDashboardWebUserStats = (state, payload = {}) => {
	return { ...state, webUsers: payload.webUserStats };
};

export default createReducer(initialState, {
	[DB_FETCH_CLIENTS_STATS]: fetchDashboardClientStats,
	[DB_FETCH_BOXES_STATS]: fetchDashboardBoxStats,
	[DB_FETCH_PASSES_STATS]: fetchDashboardPassStats,
	[DB_FETCH_USERS_STATS]: fetchDashboardWebUserStats
});
