import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm, Field } from "redux-form";
import { useHistory, withRouter } from "react-router-dom";
import { Segment, Form, Button, Header, Divider } from "semantic-ui-react";
import {
  composeValidators,
  combineValidators,
  isRequired,
  hasLengthGreaterThan,
  hasLengthLessThan,
  createValidator,
} from "revalidate";
import TextInput from "../../../app/common/form/TextInput";
import MaskedTextInput from "../../../app/common/form/MaskedTextInput";
import DropdownInput from "../../../app/common/form/DropDownInput";
import ClientSelectInput from "../../../app/common/form/ClientSelectInput";
import { useBoxes } from "../../../app/context/boxes";
import { useAsync } from "../../../app/context/async";

const mapState = (state, ownProps) => {
  let box = ownProps.box ? ownProps.box : {};

  // check if we're coming from client profile to create a new box for specific client
  let isClientSpecificNewBox = false;
  if (ownProps.match.params.clientId) {
    isClientSpecificNewBox = true;
    box.tblclientprofile = parseFloat(ownProps.match.params.clientId);
  }

  return {
    box,
    isClientSpecificNewBox,
    initialValues: box,
  };
};

// const lowBatteryOptions = [
//   { key: true, text: "Low Battery", value: true },
//   { key: false, text: "Healty Battery", value: false },
// ];
const idleOptions = [
  { key: true, text: "Active", value: true },
  { key: false, text: "Inactive", value: false },
];

const isValidBTMac = createValidator(
  (message) => (value) => {
    if (
      value &&
      !/^([0-9a-fA-F][0-9a-fA-F]:){5}([0-9a-fA-F][0-9a-fA-F])$/i.test(value)
    ) {
      return message;
    }
  },
  "Invalid MAC Address"
);

const validate = combineValidators({
  friendlyName: composeValidators(
    isRequired({ message: "Friendly Name is required" }),
    hasLengthGreaterThan(4)({
      message: "Friendly Name must be longer than 5 characters.",
    }),
    hasLengthLessThan(46)({
      message: "Friendly Name must be shorter than 45 characters.",
    })
  )(),
  btMac: composeValidators(
    isRequired({
      message:
        "A valid MAC Address is required in the format ##:##:##:##:##:##",
    }),
    isValidBTMac
  )(),
});

const BoxesForm = ({
  invalid,
  submitting,
  pristine,
  isNew,
  isClientSpecificNewBox,
  initialValues,
  handleSubmit,
}) => {
  const { updateBox, createBox } = useBoxes();
  const history = useHistory();
  const onFormSubmit = async (values) => {
    if (initialValues.id) {
      updateBox(values);
    } else {
      await createBox(values);
      history.push("/boxes");
    }
  };
  const { loading } = useAsync();

  return (
    <Segment loading={loading}>
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Form.Group widths="equal">
          <Field
            name="tblclientprofile"
            label="Client"
            component={ClientSelectInput}
            disabled={isClientSpecificNewBox}
          />
        </Form.Group>

        <Header sub color="blue" content="Box Details" />
        <Divider />
        <Form.Group widths="equal">
          <Field
            disabled={!isNew}
            name="btMac"
            label="BT Mac"
            component={MaskedTextInput}
            placeholder="__:__:__:__:__:__"
            mask="##:##:##:##:##:##"
            size="17"
          />
          {!isNew && (
            <Field
              disabled={true}
              name="secondaryGuid"
              type="text"
              label="Serial Number"
              component={TextInput}
            />
          )}

          <Field
            name="friendlyName"
            type="text"
            label="Friendly Name"
            component={TextInput}
            placeholder="Enter friendly name"
          />
          <Field
            name="pulseLength"
            type="number"
            label="Pulse Length (Milliseconds)"
            component={TextInput}
            defaultValue={1000}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Field
            name="boxtype"
            type="number"
            label="Box Type ID"
            component={TextInput}
            width={4}
          />
          {/* <Field
                name="lowBatteryFlag"
                type="text"
                label="Battery Status"
                component={DropdownInput}
                options={lowBatteryOptions}
                defaultValue={false}
                width={4}
              /> */}
          <Field
            name="idleSetting"
            type="text"
            label="Box Status"
            component={DropdownInput}
            options={idleOptions}
            defaultValue={true}
            width={4}
          />
        </Form.Group>

        <Header sub color="blue" content="Location Details" />
        <Divider />
        <Form.Group widths="equal">
          <Field
            name="locName"
            type="text"
            label="Location Name"
            component={TextInput}
            placeholder="Enter Location Name"
          />
          <Field
            name="gpsLat"
            type="text"
            label="Latitude (GPS)"
            component={TextInput}
            placeholder="Enter Latitude"
          />
          <Field
            name="gpsLong"
            type="text"
            label="Longitude (GPS)"
            component={TextInput}
            placeholder="Enter Longitude"
          />
          <Field
            name="postalCode"
            type="text"
            label="Postal Code"
            component={TextInput}
            placeholder="Enter Postal Code"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Field
            name="address1"
            type="text"
            label="Address Line 1"
            component={TextInput}
            placeholder="Enter Address Line 1"
          />
          <Field
            name="address2"
            type="text"
            label="Address Line 2"
            component={TextInput}
            placeholder="Enter Address Line 2"
          />
          <Field
            name="address3"
            type="text"
            label="Address Line 3"
            component={TextInput}
            placeholder="Enter Address Line 3"
          />
          <Field
            name="address4"
            type="text"
            label="Address Line 4"
            component={TextInput}
            placeholder="Enter Address Line 4"
          />
        </Form.Group>

        <Header sub color="blue" content="Relay Details" />
        <Divider />
        <Form.Group widths="equal">
          <Field
            name="relayName1"
            type="text"
            label="Relay 1"
            component={TextInput}
          />
          <Field
            name="relayName2"
            type="text"
            label="Relay 2"
            component={TextInput}
          />
          {/* <Field
							name="relayName3"
							type="text"
							label="Relay 3"
							component={TextInput}
							/>
							<Field
							name="relayName4"
							type="text"
							label="Relay 4"
							component={TextInput}
						/> */}
        </Form.Group>
        {/* <Form.Group widths="equal">
						<Field
						name="relayName5"
						type="text"
						label="Relay 5"
						component={TextInput}
						/>
						<Field
						name="relayName6"
						type="text"
							label="Relay 6"
							component={TextInput}
							/>
							<Field
							name="relayName7"
							type="text"
							label="Relay 7"
							component={TextInput}
							/>
							<Field
							name="relayName8"
							type="text"
							label="Relay 8"
							component={TextInput}
							/>
							</Form.Group>
					<Form.Group widths="equal">
					<Field
					name="relayName9"
							type="text"
							label="Relay 9"
							component={TextInput}
						/>
						<Field
						name="relayName10"
						type="text"
						label="Relay 10"
						component={TextInput}
						/>
						<Field
						name="relayName11"
							type="text"
							label="Relay 11"
							component={TextInput}
							/>
							<Field
							name="relayName12"
							type="text"
							label="Relay 12"
							component={TextInput}
						/>
						</Form.Group>
						<Form.Group widths="equal">
						<Field
						name="relayName13"
							type="text"
							label="Relay 13"
							component={TextInput}
							/>
							<Field
							name="relayName14"
							type="text"
							label="Relay 14"
							component={TextInput}
						/>
						<Field
						name="relayName15"
						type="text"
						label="Relay 15"
						component={TextInput}
						/>
						<Field
						name="relayName16"
						type="text"
						label="Relay 16"
							component={TextInput}
							/>
					</Form.Group> */}

        <Divider />
        <Button
          disabled={invalid || submitting || pristine || loading}
          color="blue"
          type="submit"
          loading={loading}
        >
          {isNew ? "CREATE BOX" : "UPDATE BOX"}
        </Button>
        <Button.Group floated="right">
          <Button onClick={history.goBack} type="button">
            GO BACK
          </Button>
        </Button.Group>
      </Form>
    </Segment>
  );
};

export default compose(
  withRouter,
  connect(mapState),
  reduxForm({ form: "boxesForm", enableReinitialize: true, validate })
)(BoxesForm);
