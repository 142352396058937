export const formatClientData = (client) => {
  let clientFieldsTemplate = {
    billingAddress1: "",
    billingAddress2: "",
    billingAddress3: "",
    billingAddress4: "",
    billingAddress5: "",
    billingContactFirstName: "",
    billingContactLastName: "",
    billingContactMobile: "",
    billingContactNo: "",
    billingEmail: "",
    billingName: "",
    billingPostalCode: "",
    billingType: 0,
    clientName: "",
    deactivateFlag: false,
    logoPath: "",
    primaryContactEmail: "",
    primaryContactFirstName: "",
    primaryContactLastName: "",
    primaryContactMobile: "",
    primaryContactNo: "",
    primaryContactTitle: "",
    regNo: "",
    shipToPostalCode: "",
    shippingAddress1: "",
    shippingAddress2: "",
    shippingAddress3: "",
    shippingAddress4: "",
    shippingAddress5: "",
    taxNo: "",
    tblgrouplist: {
      id: 1,
    },
  };
  let clientFields = {
    ...clientFieldsTemplate,
    ...client,
  };

  return {
    ...clientFields,
  };
};

export const formatClients = (clients) => {
  return clients.reduce((arr, client) => {
    return (arr = [...arr, { ...client.tblClientProfile, ...client }]);
  }, []);
};
