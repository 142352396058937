import React, {
  createContext,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { useState } from "react";
import { toastr } from "react-redux-toastr";
import { useApi } from "../api";
import { removeItem, replaceOldItem } from "../helpers";
import { formatClientData, formatClients } from "./formatting";
const ClientsContext = createContext({});
export const ClientsProvider = ({ children }) => {
  const api = useApi();
  const [clients, setClients] = useState();

  const updateList = (client) =>
    replaceOldItem(client, clients, setClients, "id");

  const removeFromList = (client) =>
    removeItem(client, clients, setClients, "id");

  const getClients = useCallback(() => {
    return api.client
      .get()
      .then((res) => {
        setClients(formatClients(res.data));
      })
      .catch((error) => {
        console.error("AXIOS ERROR: ", error);
      });
  }, [api]);

  useEffect(() => {
    api.authorized ? getClients() : console.log("not authorized");
    const interval = setInterval(
      api.authorized ? getClients : () => console.log("not authorized"),
      api.refreshTime
    );
    return () => clearInterval(interval);
  }, [api, getClients]);

  const createClient = async (client) => {
    const newClient = formatClientData(client);
    api.client
      .post(newClient)
      .then((res) => {
        updateList(res.data);
        toastr.success("Success", `${newClient.clientName} created.`);
      })
      .catch((err) => {
        console.log(err);
        toastr.error(
          "Oops",
          `We could not create the new client. Please try again.`
        );
      });
  };

  const updateClient = async (client) => {
    const updatedClient = formatClientData(client);
    api.client
      .post(updatedClient)
      .then((res) => {
        updateList(res.data);
        toastr.success("Success", `${updatedClient.clientName} updated.`);
      })
      .catch((err) => {
        console.log(err);
        toastr.error(
          "Oops",
          `We could not update the client. Please try again.`
        );
      });
  };

  const deleteClient = (client) => {
    const message = `Are you sure you want to delete ${client.clientName}'s profile? This operation is irreversible.`;

    toastr.confirm(message, {
      onOk: () => {
        api.client
          .delete(client)
          .then((_) => {
            removeFromList(client);
            toastr.success(
              "Success",
              `${client.clientName}'s profile deleted.`
            );
          })
          .catch((err) => {
            console.log(err);
            toastr.error(
              "Oops",
              "Something went wrong trying to delete this client, please try again."
            );
          });
      },
    });
  };

  //is not used as delete does the same
  const deactivateClient = async (client) => {
    client.deactivateFlag = !client.deactivateFlag;

    api.client
      .deactivate(client)
      .then((res) => {
        updateList(res.data);
        toastr.success("Success", `${client.clientName} has been archived.`);
      })
      .catch((err) => {
        console.log(err);
        toastr.error(
          "Oops",
          "We could not archive the client. Please try again."
        );
      });
  };
  return (
    <ClientsContext.Provider
      value={{
        clients,
        createClient,
        updateClient,
        deleteClient,
        deactivateClient,
      }}
    >
      {children}
    </ClientsContext.Provider>
  );
};
export const ClientsConsumer = ClientsContext.Consumer;
export const useClients = () => useContext(ClientsContext);
