import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { toastr } from "react-redux-toastr";
import { useApi } from "../api";
import { removeItem, replaceOldItem } from "../helpers";
import { formatUserData, formatUserForList, formatUsers } from "./formatting";
const UsersContext = createContext({});
export const UsersProvider = ({ children }) => {
  const api = useApi();
  const [users, setUsers] = useState();
  const updateList = (user) => replaceOldItem(user, users, setUsers, "id");
  const removeFromList = (user) => removeItem(user, users, setUsers, "id");
  const getUsers = useCallback(() => {
    return api.user
      .get()
      .then((res) => {
        setUsers(formatUsers(res.data));
      })
      .catch((error) => {
        console.error("AXIOS ERROR: ", error);
      });
  }, [api]);

  useEffect(() => {
    api.authorized ? getUsers() : console.log("not authorized");
    const interval = setInterval(
      api.authorized ? getUsers : () => console.log("not authorized"),
      api.refreshTime
    );
    return () => clearInterval(interval);
  }, [api, getUsers]);

  const createUser = async (user, pass) => {
    const newUser = formatUserData(user, pass);
    api.user
      .post(newUser)
      .then((res) => {
        debugger;
        updateList(formatUserForList(res.data));
        toastr.success(
          "Success",
          `Profile created for ${newUser.firstName} ${newUser.lastName}.`
        );
      })
      .catch((err) => {
        console.log(err);
        toastr.error(
          "Oops",
          "We could not create the new web user. Please try again."
        );
      });
  };

  const updateUser = async (user, pass) => {
    const updatedUser = formatUserData(user, pass);

    api.user
      .post(updatedUser)
      .then((res) => {
        console.log(res.data);

        updateList(formatUserForList(res.data));
        toastr.success(
          "Success",
          `${updatedUser.firstName} ${updatedUser.lastName}'s profile updated.`
        );
      })
      .catch((err) => {
        console.log(err);
        toastr.error(
          "Oops",
          `We could not update the user's profile. Please try again.`
        );
      });
  };

  const deleteUser = (user) => {
    const message = `Are you sure you want to delete ${user.firstName} ${user.lastName}'s profile? This operation is irreversible.`;

    toastr.confirm(message, {
      onOk: () => {
        api.user
          .delete(user)
          .then((_) => {
            removeFromList(user);
            toastr.success(
              "Success",
              `${user.firstName} ${user.lastName}'s profile has been deleted.`
            );
          })
          .catch((err) => {
            console.log(err);
            toastr.error(
              "Oops",
              "Something went wrong trying to delete this user, please try again."
            );
          });
      },
    });
  };

  const deactivateUser = async (user) => {
    user.status = !user.status;

    api.user
      .deactivateUser(user)
      .then((res) => {
        updateList(res.data);
        toastr.success(
          "Success",
          `${user.firstName} ${user.lastName}'s profile archived.`
        );
      })
      .catch((err) => {
        console.log(err);
        toastr.error(
          "Oops",
          "Something went wrong trying to archive this user, please try again."
        );
      });
  };

  return (
    <UsersContext.Provider
      value={{ users, createUser, updateUser, deleteUser, deactivateUser }}
    >
      {children}
    </UsersContext.Provider>
  );
};
export const UsersConsumer = UsersContext.Consumer;
export const useUsers = () => useContext(UsersContext);
