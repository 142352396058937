import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import NavItems from "./navItems/NavItems";
import release from "../../../config/release.json";
import { endpoint } from "../../../config";
import NavItem from "./navItems/navItem/NavItem";

class Sidebar extends Component {
  state = {
    showMobileMenu: false,
  };
  mobileMenuToggleHandler = () => {
    this.setState((prevState) => {
      return { showMobileMenu: !prevState.showMobileMenu };
    });
  };
  render() {
    return (
      <div className="side-drawer">
        <div className="side-drawer-header">
          <div className="logo">
            <Link to="/">
              <img src="/assets/reky-logo.svg" alt="ReKy Web Portal" />
            </Link>
          </div>
          <div
            className="mobile-nav-trigger"
            onClick={this.mobileMenuToggleHandler}
          >
            <Icon name="bars" />
          </div>
        </div>

        <NavItems
          visible={this.state.showMobileMenu}
          toggle={this.mobileMenuToggleHandler}
        />
        <div className="version-box">
          <p className="text-left">Version {release.version}</p>
          <p className="text-right">
            &copy; {new Date().getFullYear()}{" "}
            <a
              href="https://myreky.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              ReKy&trade;
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default withRouter(Sidebar);
