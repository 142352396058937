import React from "react";
import Table from "../../../app/common/Table";
import matchSorter from "match-sorter";
import { Icon, Button, Popup, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useClients } from "../../../app/context/clients";

const ClientsTable = () => {
  const { clients, deleteClient } = useClients();

  const columns = [
    {
      Header: "Active",
      id: "active",
      accessor: "deactivateFlag",
      className: "centered",
      Cell: ({ value }) =>
        value !== true ? (
          <Icon name="lightning" color="green" />
        ) : (
          <Icon name="archive" color="grey" />
        ),
      filterable: false,
      width: 60,
    },
    // ================================
    {
      Header: "Client Name",
      accessor: "clientName",
      className: "centered",
      id: "clientName",
      Cell: ({ value }) => (value ? value : " - "),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: ["clientName"],
        }),
      filterAll: true,
      minWidth: 60,
    },
    // ================================
    {
      Header: "Access Pass Count",
      accessor: "accessPassCount",
      id: "accessPassCount",
      Cell: ({ value }) => (value ? value : " - "),
      className: "centered",
      filterable: false,
      width: 140,
    },
    // ================================
    {
      Header: "Box Count",
      accessor: "rekyBoxCount",
      id: "rekyBoxCount",
      Cell: ({ value }) => (value ? value : " - "),
      className: "centered",
      filterable: false,
      width: 100,
    },
    // ================================
    {
      Header: "Actions",
      id: "actions",
      Cell: ({ original }) => (
        <>
          <Popup
            trigger={
              <Button
                icon="pencil alternate"
                as={Link}
                to={`/clients/client/${original.id}`}
              />
            }
            content="Edit Client Details"
          />
          <Popup
            trigger={
              <Button
                icon="cube"
                as={Link}
                to={`/clients/client-details/${original.id}`}
              />
            }
            content="View Boxes"
          />
          <Popup
            trigger={
              <Button
                icon="linkify"
                as={Link}
                to={`/boxes/create/${original.id}`}
              />
            }
            content="Link New Box"
          />
          <Popup
            trigger={
              <Button
                icon="trash alternate"
                onClick={() => deleteClient(original)}
                className="delete"
              />
            }
            content="Delete Client"
          />
        </>
      ),
      className: "no-wrap right",
      filterable: false,
      width: 180,
    },
    // ================================
  ];
  return (
    <Segment loading={!clients} basic>
      <Table columns={columns} data={clients} defaultPageSize={20} />;
    </Segment>
  );
};

export default ClientsTable;
